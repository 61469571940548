import { createAction, props } from '@ngrx/store';
import { ISubscription, IUser } from '@metutors/core/models';

export const checkMetutorsAIToken = createAction(
  '[User] check Metutors AI Token'
);

export const signIn = createAction(
  '[User] Sign In',
  props<{ user: any; returnUrl?: string }>()
);

export const socialSignIn = createAction(
  '[User] Social Sign In',
  props<{ user: any; returnUrl?: string }>()
);

export const signInSuccess = createAction(
  '[User] Sign In Success',
  props<{
    user: IUser;
    token: string;
    isDemo: number;
    timezone?: string;
    returnUrl?: string;
    profileStep: number;
    subscription: ISubscription;
  }>()
);

export const signInAdminSuccess = createAction(
  '[User] Sign In Admin Success',
  props<{
    user: IUser;
    tempToken: string;
    returnUrl?: string;
    subscription: ISubscription;
  }>()
);

export const signInFailure = createAction(
  '[User] Sign In Failure',
  props<{ error: any; errorInfo: any }>()
);

export const signInRequired = createAction(
  '[User] Sign In Required',
  props<{ returnUrl?: string }>()
);

export const submitOTPAdmin = createAction(
  '[User] Submit OTP Admin',
  props<{ otp: string }>()
);

export const resendOTPAdmin = createAction('[User] Resend OTP Admin');

export const resendOTPAdminSuccess = createAction(
  '[User] Resend OTP Admin Success',
  props<{ message: string }>()
);

export const resendOTPAdminFailure = createAction(
  '[User] Resend OTP Admin Failure',
  props<{ error: any }>()
);

export const logout = createAction('[User] Logout');

export const logoutSuccess = createAction('[User] Logout Success');

export const identifyUser = createAction('[User] Identify User');

export const identifyUserSuccess = createAction(
  '[User] Identify User Success',
  props<{
    user: IUser;
    isDemo: number;
    timezone: string;
    profileStep: number;
    subscription: ISubscription;
  }>()
);

export const identifyUserEnded = createAction('[User] Identify User Ended');

export const signupNewsLetter = createAction(
  '[User] Signup News Letter',
  props<{ email: string }>()
);

export const signupNewsLetterSuccess = createAction(
  '[User] Signup News Letter Success',
  props<{ message: string }>()
);

export const signupNewsLetterFailure = createAction(
  '[User] Signup News Letter Failure',
  props<{ error: any }>()
);

export const openSignInModal = createAction(
  '[User] Open Sign In Modal',
  props<{
    uid: string;
    returnUrl: string;
    affiliate: string;
    subHeading: string;
  }>()
);

export const closeSignInModal = createAction('[User] Close Sign In Modal');

export const routeSignInModal = createAction('[User] Route Sign In Modal');

export const openSignUpModal = createAction(
  '[User] Open Sign Up Modal',
  props<{
    title: string;
    returnUrl: string;
    affiliate: string;
  }>()
);

export const closeSignUpModal = createAction('[User] Close Sign Up Modal');

export const openAccountEmailVerificationModal = createAction(
  '[User] Open Account Email Verification Modal'
);
