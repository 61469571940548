import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'metutors-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() poster?: string;
  @Input() videoSrc?: string;
  @Input() maxHeight = '350px';

  get height(): string {
    const maxHeight = this.maxHeight;

    if (window.innerWidth < 992) {
      this.maxHeight = '350px';
    }

    if (window.innerWidth < 500) {
      this.maxHeight = '200px';
    }

    return maxHeight;
  }

  constructor() {}

  ngOnInit(): void {}
}
