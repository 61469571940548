import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  BACKEND_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  getProgramCountries(): Observable<any> {
    return this.http
      .get<{ program_countries: any[] }>(`${this.BACKEND_URL}program-country`)
      .pipe(
        map(response => {
          return response.program_countries.map(item => ({
            id: item.id,
            name: item.name,
            flag: item?.flag,
            nameEn: item.name,
            status: item.status,
            updatedAt: item.updated_at,
            countryId: +item.country_id,
            description: item.description,
            descriptionEn: item.description,
            descriptionAr: item?.description_ar,
            nameAr: item.country_details?.nameAR,
            image: item.image ? environment.countriesURL + item.image : '',
          }));
        })
      )
      .pipe(catchError(this.errorHandler));
  }

  getPrograms(): Observable<any> {
    return this.http
      .get<{ programs: any[] }>(`${this.BACKEND_URL}programs`)
      .pipe(
        map(response => {
          return response.programs.map(item => ({
            id: item.id,
            name: item.name,
            nameEn: item.name,
            nameAr: item.name_ar,
            title: item.title,
            titleEn: item.title,
            titleAr: item.title_ar,
            status: item.status,
            code: item.code,
            updatedAt: item.updated_at,
            icon: environment.programImage + item.icon,
            image: environment.programImage + item.image,
            description: item.description,
            descriptionAr: item.description_ar,
            descriptionEn: item.description,
          }));
        })
      )
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
