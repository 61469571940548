import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { NgxAutoScroll } from 'ngx-auto-scroll';
import * as fromCore from '@metutors/core/state';
import { IProgram, IUser } from '@metutors/core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  group,
  state,
  style,
  animate,
  trigger,
  transition,
} from '@angular/animations';

@Component({
  selector: 'metutors-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [
    trigger('dropMenuAnimate', [
      transition('void => *', [
        style({ transform: 'translateY(20PX)' }),
        animate(700, style({ transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ opacity: '0' })),
        ]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: '1' })),
        ]),
      ]),
    ]),
  ],
})
export class ChatbotComponent implements OnInit {
  @Input() set user(_user: IUser) {
    if (_user) {
      this.loggedUser = _user;
      this._store.dispatch(fromCore.resetChatbotStep());
      this._store.dispatch(
        fromCore.changeChatbotStep({
          step: {
            number: 4,
            date: new Date(),
            isMetutors: false,
            name: _user?.roleName === 'student' ? 'STUDENT_SM' : 'TUTOR_SM',
          },
        })
      );
      this._scrollToBottom();
    } else {
      this.loggedUser = undefined;
    }
  }

  @ViewChild(NgxAutoScroll) ngxAutoScroll?: NgxAutoScroll;

  steps$: Observable<any[]>;
  openChatbot$: Observable<boolean>;
  loadingChatbot$: Observable<boolean>;
  programs$: Observable<IProgram[] | null>;

  form: FormGroup;
  loggedUser?: IUser;
  hideChatbot = false;

  constructor(private _store: Store<any>, private _fb: FormBuilder) {
    this.form = this._fb.group({
      answer: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this._scrollToBottom();
    this.programs$ = this._store.select(fromCore.selectPrograms);
    this.steps$ = this._store.select(fromCore.selectChatbotSteps);
    this.openChatbot$ = this._store.select(fromCore.selectIsOpenChatbot);
    this.loadingChatbot$ = this._store.select(fromCore.selectIsLoadingChatbot);
  }

  initChatbot(): void {
    this._store.dispatch(fromCore.initChatbot());
    this._scrollToBottom();
  }

  handleChatbot(isOpen: boolean): void {
    isOpen ? this.closeChatbot() : this.initChatbot();
  }

  closeChatbot(): void {
    this._store.dispatch(fromCore.closeChatbot());
  }

  onSubmit(form: FormGroup, steps: any[]): void {
    if (form.valid) {
      if (steps[steps.length - 1]?.number < 4)
        this.changeChatbotStep(form.value.answer, steps, false);
      else this.changeChatbotNumber(form.value.answer, 5, false);

      this.form.reset();
      this._scrollToBottom();
    }
  }

  onWriteEmail(event: any, steps: any): void {
    this.changeChatbotStep(event.target.value, steps, false);
    this._scrollToBottom();
  }

  changeChatbotNumber(name: string, number: number, isMetutors: boolean): void {
    this._store.dispatch(
      fromCore.changeChatbotStep({
        step: {
          name,
          number,
          isMetutors,
          date: new Date(),
        },
      })
    );
    this._scrollToBottom();
  }

  changeChatbotStep(name: string, steps: any[], isMetutors: boolean): void {
    this._store.dispatch(
      fromCore.changeChatbotStep({
        step: {
          name,
          isMetutors,
          date: new Date(),
          number: steps.length + 1,
        },
      })
    );
    this._scrollToBottom();
  }

  private _scrollToBottom(): void {
    this.ngxAutoScroll?.forceScrollDown();
  }
}
