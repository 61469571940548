import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'metutors-language-currency-modal',
  templateUrl: './language-currency-modal.component.html',
  styleUrls: ['./language-currency-modal.component.scss'],
})
export class LanguageCurrencyModalComponent implements OnInit {
  @Input() currencyRates: any[];
  @Input() selectedCurrency: any;
  @Input() selectedLanguage: string;
  @Input() showModal: boolean = false;

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() currencySelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() languageSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
}
