import { NgModule } from '@angular/core';
import { GPTGuard, AuthGuard } from '@guards';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/gpt/gpt.module').then(m => m.GptModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 60],
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [GPTGuard, AuthGuard],
})
export class AppRoutingModule {}
