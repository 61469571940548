import { createAction, props } from '@ngrx/store';

export const initChatbot = createAction('[Chatbot] Initiate Chatbot');

export const closeChatbot = createAction('[Chatbot] Close Chatbot');

export const resetChatbotStep = createAction('[Chatbot] Reset Chatbot Step');

export const changeChatbotStep = createAction(
  '[Chatbot] Change Chatbot Step',
  props<{ step: any }>()
);

export const changeChatbotStepSuccess = createAction(
  '[Chatbot] Change Chatbot Step Success',
  props<{ step: any }>()
);

export const changeChatbotStepFailure = createAction(
  '[Chatbot] Change Chatbot Step Failure',
  props<{ error: any }>()
);

export const changeChatbotStepEnded = createAction(
  '[Chatbot] Change Chatbot Step Ended'
);
