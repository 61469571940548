import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  baseUrl = environment.API_URL;

  constructor(private http: HttpClient) {}

  getGptFeedbackOptions(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}subscription/feedback/params`);
  }
}
