import { JsonLdModule } from 'ngx-seo';
import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';

import icons from '../../assets/svg/svg-icons';

// Modals
import {
  PaymentModalComponent,
  ConfirmModalComponent,
  SuccessModalComponent,
} from '@metutors/shared/modals';

// Components
import {
  ModalComponent,
  SigninFormComponent,
  ReviewRateComponent,
  RolesSelectComponent,
  VideoPlayerComponent,
  CharCounterComponent,
  SubmitButtonComponent,
  ModalComponentTemplate,
  GridPlaceholderComponent,
  ChooseCountryDialogComponent,
} from '@metutors/shared/components';

// Directives
import {
  LetDirective,
  DefaultAvatarDirective,
} from '@metutors/shared/directives';

import { MoneyPipe, UppercasePipe } from './pipes';

@NgModule({
  declarations: [
    // Pipes
    MoneyPipe,
    UppercasePipe,

    // Components
    ModalComponent,
    SigninFormComponent,
    ReviewRateComponent,
    VideoPlayerComponent,
    RolesSelectComponent,
    CharCounterComponent,
    SubmitButtonComponent,
    ModalComponentTemplate,
    GridPlaceholderComponent,
    ChooseCountryDialogComponent,

    // Modals
    ConfirmModalComponent,
    PaymentModalComponent,
    SuccessModalComponent,

    // Directives
    LetDirective,
    DefaultAvatarDirective,
  ],
  imports: [
    FormsModule,
    CommonModule,
    MomentModule,
    VgCoreModule,
    RouterModule,
    JsonLdModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    TranslateModule,
    VgControlsModule,
    VgBufferingModule,
    MatCheckboxModule,
    MatFormFieldModule,
    VgOverlayPlayModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatSelectSearchModule,
    BsDropdownModule.forRoot(),
    AngularSvgIconModule.forRoot(),
  ],
  exports: [
    // Pipes
    MoneyPipe,
    UppercasePipe,

    // Components
    MomentModule,
    ModalComponent,
    SigninFormComponent,
    ReviewRateComponent,
    CharCounterComponent,
    VideoPlayerComponent,
    RolesSelectComponent,
    SubmitButtonComponent,
    ModalComponentTemplate,
    GridPlaceholderComponent,
    ChooseCountryDialogComponent,

    // Modals
    ConfirmModalComponent,
    PaymentModalComponent,
    SuccessModalComponent,

    // Directives
    LetDirective,
    DefaultAvatarDirective,

    // Modules
    JsonLdModule,
    TranslateModule,
    AngularSvgIconModule,
    NgxMatSelectSearchModule,
  ],
})
export class SharedModule {
  constructor(private _iconReg: SvgIconRegistryService) {
    for (const iconKey in icons) {
      if (Object.prototype.hasOwnProperty.call(icons, iconKey)) {
        this._iconReg.addSvg(iconKey, icons[iconKey]);
      }
    }
  }
}
