import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@metutors/shared/shared.module';

import { NavbarComponent } from './navbar';
import { FooterComponent } from './footer';
import { ChatbotComponent } from './chatbot';
import { SigninModalComponent } from './signin-modal';
import { UnderMaintenanceComponent } from './under-maintenance';
import { LanguageCurrencyModalComponent } from './language-currency-modal';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    ChatbotComponent,
    SigninModalComponent,
    UnderMaintenanceComponent,
    LanguageCurrencyModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatButtonModule,
    TranslateModule,
    NgxAutoScrollModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    ChatbotComponent,
    SigninModalComponent,
    UnderMaintenanceComponent,
  ],
})
export class ComponentsModule {}
