import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import camelcaseKeys from 'camelcase-keys';
import { FeedbackService } from '@services';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as feedbackActions from '@metutors/core/state/actions/feedback.actions';

@Injectable()
export class FeedbackEffects {
  loadGptFeedbackOptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(feedbackActions.loadGptFeedbackOptions),
      mergeMap(() =>
        this._feedbackService.getGptFeedbackOptions().pipe(
          map(feedbackOptions =>
            feedbackActions.loadGptFeedbackOptionsSuccess({
              feedbackOptions: camelcaseKeys(feedbackOptions, { deep: true }),
            })
          ),
          catchError(error =>
            of(
              feedbackActions.loadGptFeedbackOptionsFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _feedbackService: FeedbackService
  ) {}
}
