import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidationUtilsService {
  constructor() {}

  passwordsMisatchValidator(
    passwordControlKey: string,
    confirmPasswordControlKey: string
  ): any {
    return (formGroup: FormGroup): { [key: string]: boolean } | null => {
      if (!formGroup) {
        return null;
      }

      const password = formGroup.get(passwordControlKey);
      const confirmPassword = formGroup.get(confirmPasswordControlKey);

      if (
        !password ||
        !confirmPassword ||
        !password.value ||
        !confirmPassword.value
      ) {
        return null;
      }

      if (password.value !== confirmPassword.value) {
        return { passwordMismatch: true };
      }

      return null;
    };
  }

  minPasswordValidation(control: FormControl) {
    let value = (control.value || '').trim().length;
    let less = value < 6;

    return less ? { passwordError: 'PASSWORD_LEAST_CHARACTERS' } : null;
  }

  passwordValidation(control: FormControl) {
    if (!control.value) {
      return null;
    }

    const value = (control.value || '').trim();

    return value?.length < 6
      ? { passwordError: 'PASSWORD_LEAST_CHARACTERS' }
      : !/\d/.test(value)
      ? { passwordError: 'PASSWORD_INCLUDE_NUMBER' }
      : !/[A-Z]/.test(value)
      ? { passwordError: 'PASSWORD_INCLUDE_UPPERCASE' }
      : null;
  }
}
