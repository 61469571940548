<metutors-modal [showModal]="showModal" [size]="'medium'" [showHeader]="true" (closeModal)="closeModal.emit()"
                [template]="template" [heading]="'CONFIRM_PAYMENT'" *ngIf="paymentInfo?.shopperResultUrl">
  <ng-template #template>
    <ng-container [ngSwitch]="loading">
      <metutors-grid-placeholder *ngSwitchCase="true"></metutors-grid-placeholder>

      <div class="pt-2">
        <form [action]="paymentInfo?.shopperResultUrl" class="paymentWidgets" data-brands="VISA MASTER"></form>
      </div>
    </ng-container>
  </ng-template>
</metutors-modal>
