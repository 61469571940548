import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthService } from '@services';
import { Router } from '@angular/router';
import camelcaseKeys from 'camelcase-keys';
import { Injectable } from '@angular/core';
import * as fromRoot from '@metutors/state';
import * as fromCore from '@metutors/core/state';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import * as userActions from '../actions/user.actions';
import { UserRole, SocialProvider } from '@metutors/config';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlertNotificationService } from '@metutors/core/components';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class UserEffects {
  checkMetutorsAIToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.checkMetutorsAIToken),
      withLatestFrom(
        this._store.select(fromRoot.selectQueryParam('token')),
        this._store.select(fromRoot.selectQueryParam('openLoginModal'))
      ),
      mergeMap(([_, token, openLoginModal]) => {
        if (token) {
          const jwtHelper = new JwtHelperService();
          const isExpired = jwtHelper.isTokenExpired(token);

          if (!isExpired) {
            const decodeToken = camelcaseKeys(jwtHelper.decodeToken(token), {
              deep: true,
            });
            const user: any = decodeToken?.user;
            const subscription: any = decodeToken?.subscription;

            if (user && token) {
              this._router.navigate([], {
                queryParams: {
                  token: null,
                },
                queryParamsHandling: 'merge',
              });

              return of(
                userActions.signInSuccess({
                  user,
                  token,
                  subscription,
                  isDemo: user.isDemo,
                  timezone: user?.userTimezone,
                  profileStep:
                    user &&
                    user.profileCompletedStep &&
                    !isNaN(user.profileCompletedStep)
                      ? +user.profileCompletedStep + 1
                      : 1,
                })
              );
            } else {
              return of(userActions.identifyUserEnded());
            }
          } else {
            return of(userActions.identifyUserEnded());
          }
        } else {
          if (openLoginModal) {
            this._router.navigate([], {
              queryParams: {
                openLoginModal: null,
              },
              queryParamsHandling: 'merge',
            });

            return of(
              userActions.openSignInModal({
                uid: '',
                affiliate: '',
                returnUrl: '/full',
                subHeading: 'GPT_MODAL',
              })
            );
          } else {
            return of(userActions.identifyUserEnded());
          }
        }
      })
    )
  );

  identifyUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.identifyUser),
      withLatestFrom(this._store.select(fromCore.selectToken)),
      mergeMap(([_, token]) => {
        const jwtHelper = new JwtHelperService();
        const decodeToken = camelcaseKeys(jwtHelper.decodeToken(token), {
          deep: true,
        });
        const user: any = decodeToken?.user;
        const subscription: any = decodeToken?.subscription;

        if (user && token) {
          return of(
            userActions.identifyUserSuccess({
              user,
              subscription,
              isDemo: user.isDemo,
              timezone: user?.userTimezone,
              profileStep:
                user &&
                user.profileCompletedStep &&
                !isNaN(user.profileCompletedStep)
                  ? +user.profileCompletedStep + 1
                  : 1,
            })
          );
        } else {
          return of(userActions.identifyUserEnded());
        }
      })
    )
  );

  signIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.signIn),
      withLatestFrom(
        this._store.select(fromRoot.selectQueryParam('returnUrl'))
      ),
      mergeMap(([{ user, returnUrl }, redirectUrl]) =>
        this._authService.login(user).pipe(
          map(response => {
            if (!response?.status) {
              return userActions.signInFailure({
                error: response?.message,
                errorInfo: { verified: response?.verified },
              });
            }

            const jwtHelper = new JwtHelperService();
            const decodeToken = camelcaseKeys(
              jwtHelper.decodeToken(response.token),
              {
                deep: true,
              }
            );
            const user: any = decodeToken?.user;
            const subscription: any = decodeToken?.subscription;

            if (user?.roleId?.toString() === UserRole.admin.toString()) {
              return userActions.signInAdminSuccess({
                user,
                subscription,
                tempToken: response.token,
                returnUrl: returnUrl || redirectUrl,
              });
            } else {
              return userActions.signInSuccess({
                user,
                subscription,
                isDemo: user.isDemo,
                token: response.token,
                timezone: user?.userTimezone,
                profileStep:
                  user &&
                  user.profileCompletedStep &&
                  !isNaN(user.profileCompletedStep)
                    ? +user.profileCompletedStep + 1
                    : 1,
                returnUrl: response?.returnUrl || returnUrl || redirectUrl,
              });
            }
          }),
          catchError(error =>
            of(
              userActions.signInFailure({
                error: error?.error?.message || error?.error?.errors,
                errorInfo: error?.error,
              })
            )
          )
        )
      )
    )
  );

  socialSignIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.socialSignIn),
      withLatestFrom(
        this._store.select(fromRoot.selectQueryParam('returnUrl'))
      ),
      mergeMap(([{ user, returnUrl }, redirectUrl]) => {
        if (user.provider === SocialProvider.google) {
          return this._authService
            .googleSignIn({
              ...user,
              lang: this._translate.currentLang,
              ip: localStorage.getItem('my-ip'),
            })
            .pipe(
              map(response => {
                const jwtHelper = new JwtHelperService();
                const decodeToken = camelcaseKeys(
                  jwtHelper.decodeToken(response),
                  {
                    deep: true,
                  }
                );
                const user: any = decodeToken?.user;
                const subscription: any = decodeToken?.subscription;

                if (user?.roleId?.toString() === UserRole.admin.toString()) {
                  return userActions.signInAdminSuccess({
                    user,
                    subscription,
                    tempToken: response,
                    returnUrl: returnUrl || redirectUrl,
                  });
                } else {
                  return userActions.signInSuccess({
                    user,
                    subscription,
                    token: response,
                    isDemo: user.isDemo,
                    timezone: user?.userTimezone,
                    profileStep:
                      user &&
                      user.profileCompletedStep &&
                      !isNaN(user.profileCompletedStep)
                        ? +user.profileCompletedStep + 1
                        : 1,
                    returnUrl: returnUrl || redirectUrl,
                  });
                }
              }),
              catchError(error =>
                of(
                  userActions.signInFailure({
                    error: error?.error?.message || error?.error?.errors,
                    errorInfo: error?.error,
                  })
                )
              )
            );
        } else {
          return this._authService
            .facebookSignIn({
              ...user,
              lang: this._translate.currentLang,
              ip: localStorage.getItem('my-ip'),
            })
            .pipe(
              map(response => {
                const jwtHelper = new JwtHelperService();
                const decodeToken = camelcaseKeys(
                  jwtHelper.decodeToken(response),
                  {
                    deep: true,
                  }
                );
                const user: any = decodeToken?.user;
                const subscription: any = decodeToken?.subscription;

                if (user?.roleId?.toString() === UserRole.admin.toString()) {
                  return userActions.signInAdminSuccess({
                    user,
                    subscription,
                    tempToken: response,
                    returnUrl: returnUrl || redirectUrl,
                  });
                } else {
                  return userActions.signInSuccess({
                    user,
                    subscription,
                    token: response,
                    isDemo: user.isDemo,
                    timezone: user?.userTimezone,
                    profileStep:
                      user &&
                      user.profileCompletedStep &&
                      !isNaN(user.profileCompletedStep)
                        ? +user.profileCompletedStep + 1
                        : 1,
                    returnUrl: returnUrl || redirectUrl,
                  });
                }
              }),
              catchError(error =>
                of(
                  userActions.signInFailure({
                    error: error?.error?.message || error?.error?.errors,
                    errorInfo: error?.error,
                  })
                )
              )
            );
        }
      })
    )
  );

  signInSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(userActions.signInSuccess),
        withLatestFrom(
          this._store.select(fromCore.selectProfileStep),
          this._store.select(fromCore.selectUser),
          this._store.select(fromCore.selectTutorUID)
        ),
        map(([action, step, user, uid]) => {
          localStorage.removeItem('loggedOut');
          localStorage.removeItem('loggedOutTabs');

          if (action.returnUrl) {
            this._router.navigateByUrl(action.returnUrl);
          } else {
            if (user?.roleId?.toString() === UserRole.student.toString()) {
              if (uid && this._router.url !== '/signin') {
                this._router.navigate([this._router.url]);
              } else {
                this._router.navigate(['/student']);
              }
            } else if (user?.roleId?.toString() === UserRole.tutor.toString()) {
              if (step <= 4)
                // 5 for the old scenario and 4 for the new one
                this._router.navigate(['/profile', 'complete-profile']);
              else this._router.navigate(['/tutor']);
            } else if (user?.roleId?.toString() === UserRole.admin.toString()) {
              if (uid && this._router.url !== '/signin') {
                this._router.navigate([this._router.url]);
              } else {
                this._router.navigate(['/admin']);
              }
            } else {
              this._router.navigate(['/']);
            }
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  signInRequired$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(userActions.signInRequired),
        map(action => {
          this._router.navigate(['/signin'], {
            queryParams: { returnUrl: action.returnUrl },
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  signInRequiredLogout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.signInRequired),
      map(() => fromCore.logout())
    )
  );

  submitOTPAdmin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.submitOTPAdmin),
      withLatestFrom(
        this._store.select(fromCore.selectTempToken),
        this._store.select(fromRoot.selectQueryParam('returnUrl'))
      ),
      mergeMap(([action, token, returnUrl]) =>
        this._authService.submitOTPAdmin(action.otp).pipe(
          map(() => {
            const jwtHelper = new JwtHelperService();
            const decodeToken = camelcaseKeys(jwtHelper.decodeToken(token), {
              deep: true,
            });
            const user: any = decodeToken?.user;
            const subscription: any = decodeToken?.subscription;

            return userActions.signInSuccess({
              user,
              subscription,
              token: token || '',
              isDemo: user.isDemo,
              profileStep:
                user &&
                user.profileCompletedStep &&
                !isNaN(user.profileCompletedStep)
                  ? +user.profileCompletedStep + 1
                  : 1,
              returnUrl,
            });
          }),
          catchError(error =>
            of(
              userActions.signInFailure({
                error: error?.error?.message || error?.error?.errors,
                errorInfo: error?.error,
              })
            )
          )
        )
      )
    )
  );

  resendOTPAdmin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.resendOTPAdmin),
      mergeMap(() =>
        this._authService.resendOTPAdmin().pipe(
          map(response =>
            userActions.resendOTPAdminSuccess({
              message: response.message,
            })
          ),
          catchError(error =>
            of(
              userActions.resendOTPAdminFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.logout),
      withLatestFrom(this._store.select(fromCore.selectToken)),
      mergeMap(([_, token]) => {
        if (token)
          return this._authService.logout().pipe(
            map(() => userActions.logoutSuccess()),
            catchError(_ => of(userActions.logoutSuccess()))
          );
        else return of(userActions.logoutSuccess());
      })
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(userActions.logoutSuccess),
        map(() => {
          this._router.navigateByUrl('/signin');

          const loggedOutTabs = localStorage.getItem('loggedOutTabs');

          if (loggedOutTabs === 'true') {
            localStorage.removeItem('loggedOut');
            localStorage.removeItem('loggedOutTabs');
          } else {
            localStorage.setItem('loggedOut', 'true');
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  signupNewsLetter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.signupNewsLetter),
      mergeMap(action =>
        this._authService.signupNewsLetter(action.email).pipe(
          map(({ message }) =>
            userActions.signupNewsLetterSuccess({
              message,
            })
          ),
          catchError(error =>
            of(
              userActions.signupNewsLetterFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  successMessages$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ...[
            userActions.resendOTPAdminSuccess,
            userActions.signupNewsLetterSuccess,
          ]
        ),
        map(action => {
          if (action.message) {
            return this._alertNotificationService.success(action.message);
          } else {
            return this._alertNotificationService.success(
              'INFORMATION_UPDATED_SUCCESSFULLY'
            );
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  failureMessages$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ...[
            userActions.signInFailure,
            userActions.resendOTPAdminFailure,
            userActions.signupNewsLetterFailure,
          ]
        ),
        map(action => {
          if (action.error) {
            const isArray = Array.isArray(action?.error);
            const message = isArray
              ? action?.error && action?.error?.length
                ? action.error[0]
                : action.error
              : action.error;

            return this._alertNotificationService.error(message);
          } else {
            return this._alertNotificationService.error('SOMETHING_WENT_WRONG');
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private _router: Router,
    private _store: Store<any>,
    private _actions$: Actions,
    private _authService: AuthService,
    private _translate: TranslateService,
    private _alertNotificationService: AlertNotificationService
  ) {
    window.onstorage = () => {
      const loggedOut = localStorage.getItem('loggedOut');

      if (loggedOut === 'true') {
        this._store.dispatch(fromCore.logout());
        localStorage.setItem('loggedOutTabs', 'true');
        localStorage.removeItem('loggedOut');
      }
    };
  }
}
