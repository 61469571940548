/* eslint-disable import/no-deprecated */
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromCore from '@metutors/core/state';
import { ChatbotService } from '@metutors/core/services';
import * as chatbotActions from '../actions/chatbot.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ChatbotEffects {
  changeChatbotStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(chatbotActions.changeChatbotStep),
      withLatestFrom(
        this._store.select(fromCore.selectUser),
        this._store.select(fromCore.selectChatbotSteps)
      ),
      mergeMap(([action, user, steps]) => {
        let body = {};

        if (user) {
          if (action.step?.number === 5) {
            body = {
              prompt: action.step?.name,
              user_id: localStorage.getItem('my-ip'),
              status: user?.roleName === 'teacher' ? 'tutor' : user?.roleName,
            };

            return this._chatbotService.promptChatbot(body).pipe(
              map((result: any) =>
                chatbotActions.changeChatbotStepSuccess({
                  step: {
                    number: 5,
                    name: result,
                    isMetutors: true,
                    date: new Date(),
                    question: action.step?.name,
                  },
                })
              ),
              catchError(error =>
                of(
                  chatbotActions.changeChatbotStepFailure({
                    error,
                  })
                )
              )
            );
          } else {
            return of(chatbotActions.changeChatbotStepEnded());
          }
        } else {
          if (action.step?.number === 5) {
            const items = steps.filter(step => step.number === 4);

            body = {
              prompt: action.step?.name,
              user_id: localStorage.getItem('my-ip'),
              status:
                items[items.length - 1]?.name === 'TUTOR_SM'
                  ? 'tutor'
                  : items[items.length - 1]?.name === 'STUDENT_SM'
                  ? 'student'
                  : 'parent',
            };

            return this._chatbotService.promptChatbot(body).pipe(
              map((result: any) => {
                if (action.step?.number === 5) {
                  return chatbotActions.changeChatbotStepSuccess({
                    step: {
                      number: 5,
                      name: result,
                      isMetutors: true,
                      date: new Date(),
                      question: action.step?.name,
                    },
                  });
                } else {
                  return chatbotActions.changeChatbotStepEnded();
                }
              }),

              catchError(error =>
                of(
                  chatbotActions.changeChatbotStepFailure({
                    error: error?.error?.text,
                  })
                )
              )
            );
          } else {
            return of(chatbotActions.changeChatbotStepEnded());
          }
        }
      })
    )
  );

  changeChatbotStepSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(chatbotActions.changeChatbotStepSuccess),
      withLatestFrom(
        this._store.select(fromCore.selectUser),
        this._store.select(fromCore.selectChatbotSteps)
      ),
      mergeMap(([action, user, steps]) => {
        let body = {};

        if (user) {
          body = {
            email: user.email,
            name: user.firstName,
            question: action.step?.question,
            role_name: user?.roleName === 'teacher' ? 'tutor' : user?.roleName,
          };
        } else {
          const items = steps.filter(step => step.number === 4);

          body = {
            name: steps[1]?.name,
            email: steps[2]?.name,
            question: action.step?.question,
            role_name:
              items[items.length - 1]?.name === 'TUTOR_SM'
                ? 'tutor'
                : items[items.length - 1]?.name === 'STUDENT_SM'
                ? 'student'
                : 'parent',
          };
        }

        return this._chatbotService.submitChatbot(body).pipe(
          map(() => chatbotActions.changeChatbotStepEnded()),
          catchError(() => of(chatbotActions.changeChatbotStepEnded()))
        );
      })
    )
  );

  constructor(
    private _store: Store<any>,
    private _actions$: Actions,
    private _chatbotService: ChatbotService
  ) {}
}
