<div class="country position-relative">
  <button mat-button mat-dialog-close (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>

  <div mat-dialog-content>
    <div class="text-center country" style="background-image: url('./assets/png/world-map.png')">
      <h1 class="f-size-20-md-sm">{{ 'CHOOSE_COUNTRY_TITLE' | translate }}</h1>
      <div class="find-country d-flex align-items-center justify-content-between flex-column-sm">
        <input type="text" class="form-control w-100-sm" placeholder="{{ 'FIND_COUNTRY' | translate }}"
          (keyup)="onChangeCountry($event)" meTutorsTrimInput>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3" *ngFor="let country of tempCountries">
        <h3 class="cursor-pointer" (click)="onSelectCountry(country)">
          <span [innerHTML]="country?.flag" class="me-2"></span>
          {{ country?.name }}
        </h3>
      </div>
    </div>
  </div>
</div>
