import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'metutors-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss'],
})
export class UnderMaintenanceComponent implements OnInit {
  today = new Date();

  constructor() {}

  ngOnInit(): void {}
}
