<div class="dialog position-relative {{ extraClasses }}">
  <div class="d-flex align-items-center" mat-dialog-title *ngIf="showHeader">
    <h2 class="flex-grow-1 m-0 d-flex flex-column">
      <div class="d-flex align-items-center">
        <bdi>{{ heading | translate }}</bdi>
        <span class="status-icon text-capitalize ms-2" *ngIf="status" [ngClass]="status">{{ status }}</span>
      </div>
      <span class="d-block mt-2"><bdi>{{ subHeading | translate }}</bdi></span>
    </h2>

    <button class="text-secondary" mat-dialog-close="true" mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="pt-2">
    <ng-template [ngTemplateOutlet]="data.template"></ng-template>
  </mat-dialog-content>
</div>
