import { createAction, props } from '@ngrx/store';

export const setWebsitePaddingTop = createAction(
  '[General] Set Website Padding Top',
  props<{ paddingTop: number }>()
);

export const setMetaTags = createAction(
  '[General] Set Meta Tags',
  props<{ tags: any }>()
);

export const openLanguageCurrencyModal = createAction(
  '[General] Open Language Currency Modal'
);

export const closeLanguageCurrencyModal = createAction(
  '[General] Close Language Currency Modal'
);

export const openSuccessModal = createAction('[General] Open Success Modal');

export const closeSuccessModal = createAction('[General] Close Success Modal');

export const doNothing = createAction('[General] Do Nothing');
