﻿<div class="alert-item-wrapper" [ngClass]="position">
  <alert-item *ngFor="let item of notifications; let i = index"
              [item]="item" [timeOut]="timeOut"
              [clickToClose]="clickToClose" [maxLength]="maxLength"
              [showProgressBar]="showProgressBar"
              [pauseOnHover]="pauseOnHover"
              [theClass]="theClass" [rtl]="rtl"
              [animate]="animate" [position]="i">
  </alert-item>
</div>
