import * as moment from 'moment';
import { createReducer, on } from '@ngrx/store';
import * as gptActions from '../actions/gpt.actions';
import * as userActions from '../actions/user.actions';
import { ISubscription, IUser } from '@metutors/core/models';
import { UserRole, generalConstants } from '@metutors/config';

export interface State {
  // Sign up
  affiliate: string;
  returnUrl: string;
  isSignUp: boolean;
  registerStep: number;
  registerEmail: string;
  isVerifyEmail: boolean;
  isSocialSignIn: boolean;
  registerUserType: number;
  isResendEmailConfirm: boolean;

  // Sign in
  token?: string;
  tutorUID: string;
  isSignIn: boolean;
  tempToken?: string;
  signUpTitle: string;
  signInFailure?: string;
  openSignInModal: boolean;
  signInSubHeading: string;
  openSignUpModal: boolean;
  isSubmitOTPAdmin: boolean;
  isResendOTPAdmin: boolean;
  showEmailVerificationModal: boolean;

  // Complete profile
  isDemo: number;
  timezone?: string;
  user: IUser | null;
  profileStep: number;
  subscription: ISubscription | null;

  // News Letter
  isSignNewsLetter: boolean;
  isVerifyNewsLetter: boolean;
  verifyNewsLetterError: boolean;
}

export const initialState: State = {
  isDemo: 0,
  user: null,
  timezone: '',
  tutorUID: '',
  returnUrl: '',
  affiliate: '',
  profileStep: 1,
  registerStep: 1,
  isSignIn: false,
  signUpTitle: '',
  isSignUp: false,
  registerEmail: '',
  subscription: null,
  signInSubHeading: '',
  isVerifyEmail: false,
  isSocialSignIn: false,
  openSignInModal: false,
  openSignUpModal: false,
  isSubmitOTPAdmin: false,
  isSignNewsLetter: false,
  isResendOTPAdmin: false,
  isVerifyNewsLetter: false,
  isResendEmailConfirm: false,
  verifyNewsLetterError: false,
  showEmailVerificationModal: false,
  registerUserType: UserRole.student,
};

export const reducer = createReducer(
  initialState,

  on(
    userActions.identifyUserSuccess,
    (state, { profileStep, user, isDemo, timezone }) => ({
      ...state,
      user,
      isDemo,
      timezone,
      profileStep,
    })
  ),

  on(userActions.signIn, state => ({
    ...state,
    isSignIn: true,
    signInFailure: '',
  })),

  on(userActions.socialSignIn, state => ({
    ...state,
    isSocialSignIn: true,
    signInFailure: '',
  })),

  on(
    userActions.signInSuccess,
    (state, { token, profileStep, user, isDemo, timezone, subscription }) => ({
      ...state,
      user,
      token,
      isDemo,
      timezone,
      profileStep,
      subscription,
      isSignIn: false,
      signInFailure: '',
      tempToken: undefined,
      isSocialSignIn: false,
      isSubmitOTPAdmin: false,
    })
  ),

  on(
    userActions.signInAdminSuccess,
    (state, { tempToken, user, subscription }) => ({
      ...state,
      user,
      tempToken,
      subscription,
      isSignIn: false,
      signInFailure: '',
      isSocialSignIn: false,
    })
  ),

  on(userActions.signInFailure, (state, { error }) => ({
    ...state,
    isSignIn: false,
    isSocialSignIn: false,
    signInFailure: error,
    isSubmitOTPAdmin: false,
  })),

  on(userActions.signInFailure, (state, { errorInfo }) => ({
    ...state,
    showEmailVerificationModal: +errorInfo?.verified! === 0 ? true : false,
  })),

  on(userActions.openAccountEmailVerificationModal, state => ({
    ...state,
    showEmailVerificationModal: true,
  })),

  on(
    userActions.openSignInModal,
    (state, { uid, subHeading, returnUrl, affiliate }) => ({
      ...state,
      returnUrl,
      affiliate,
      tutorUID: uid,
      openSignInModal: true,
      signInSubHeading: subHeading,
    })
  ),

  on(userActions.closeSignInModal, state => ({
    ...state,
    openSignInModal: false,
  })),

  on(userActions.openSignUpModal, (state, { title, returnUrl, affiliate }) => ({
    ...state,
    returnUrl,
    affiliate,
    signUpTitle: title,
    openSignUpModal: true,
  })),

  on(userActions.closeSignUpModal, state => ({
    ...state,
    openSignUpModal: false,
  })),

  on(userActions.routeSignInModal, userActions.signInSuccess, state => ({
    ...state,
    openSignInModal: false,
    openSignUpModal: false,
  })),

  on(userActions.submitOTPAdmin, state => ({
    ...state,
    signInFailure: '',
    isSubmitOTPAdmin: true,
  })),

  on(userActions.resendOTPAdmin, state => ({
    ...state,
    signInFailure: '',
    isResendOTPAdmin: true,
  })),

  on(
    userActions.resendOTPAdminSuccess,
    userActions.resendOTPAdminFailure,
    state => ({
      ...state,
      isResendOTPAdmin: false,
      signInFailure: '',
    })
  ),

  on(gptActions.gptDestroyIntroJSSuccess, (state, { user, token }) => ({
    ...state,
    user,
    token,
  })),

  on(
    gptActions.getGPTFreeTrialSuccess,
    gptActions.unsubscribeGPTPackageSuccess,
    gptActions.verifyGPTPackageSubscriptionSuccess,
    (state, { subscription, token }) => ({
      ...state,
      token,
      subscription,
    })
  ),

  on(gptActions.loadUserGPTSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    subscription,
  })),

  on(userActions.signupNewsLetter, state => ({
    ...state,
    isSignNewsLetter: true,
  })),

  on(
    userActions.signupNewsLetterSuccess,
    userActions.signupNewsLetterFailure,
    state => ({
      ...state,
      isSignNewsLetter: false,
    })
  )
);

export const selectToken = (state: State): string | undefined => state.token;

export const selectTempToken = (state: State): string | undefined =>
  state.tempToken;

export const selectSignInFailure = (state: State): string | undefined =>
  state.signInFailure;

export const selectIsSignUp = (state: State): boolean => state.isSignUp;

export const selectIsSignNewsLetter = (state: State): boolean =>
  state.isSignNewsLetter;

export const selectIsVerifyNewsLetter = (state: State): boolean =>
  state.isVerifyNewsLetter;

export const selectVerifyNewsLetterError = (state: State): boolean =>
  state.verifyNewsLetterError;

export const selectRegisterStep = (state: State): number => state.registerStep;

export const selectRegisterEmail = (state: State): string =>
  state.registerEmail;

export const selectRegisterUserType = (state: State): number =>
  state.registerUserType;

export const selectIsSignIn = (state: State): boolean => state.isSignIn;

export const selectIsSocialSignIn = (state: State): boolean =>
  state.isSocialSignIn;

export const selectIsOpenSignInModal = (state: State): boolean =>
  state.openSignInModal;

export const selectSignInSubHeading = (state: State): string =>
  state.signInSubHeading;

export const selectIsOpenSignUpModal = (state: State): boolean =>
  state.openSignUpModal;

export const selectSignUpTitle = (state: State): string => state.signUpTitle;

export const selectReturnUrl = (state: State): string => state.returnUrl;

export const selectAffiliate = (state: State): string => state.affiliate;

export const selectTutorUID = (state: State): string => state.tutorUID;

export const selectIsSubmitOTPAdmin = (state: State): boolean =>
  state.isSubmitOTPAdmin;

export const selectIsResendOTPAdmin = (state: State): boolean =>
  state.isResendOTPAdmin;

export const selectProfileStep = (state: State): number => state.profileStep;

export const selectStudentIsDemo = (state: State): number => state.isDemo;

export const selectUserTimezone = (state: State): string => state.timezone!;

export const selectUser = (state: State): IUser | null => state.user;

export const selectSubscription = (state: State): ISubscription | null =>
  state.subscription;

export const selectIsVerifyEmail = (state: State): boolean =>
  state.isVerifyEmail;

export const selectIsResendEmailConfirm = (state: State): boolean =>
  state.isResendEmailConfirm;

export const selectShowEmailVerificationModal = (state: State): boolean =>
  state.showEmailVerificationModal;

export const selectUserGPTRemaingDays = (state: State): number => {
  if (state.subscription?.updatedAt) {
    const deadline = new Date(state.subscription?.updatedAt);
    deadline.setDate(
      deadline.getDate() + generalConstants.trialSubscriptionDays
    );

    const endDate = moment(deadline, 'YYYY-MM-DD');
    const currentDate = moment().startOf('day');

    return Math.floor(moment.duration(endDate.diff(currentDate)).asDays());
  }

  return 0;
};
