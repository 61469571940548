import {
  Action,
  createSelector,
  combineReducers,
  createFeatureSelector,
} from '@ngrx/store';

export * from './actions';
import * as fromRoot from '@metutors/state';
import * as featureKeys from './feature-keys';

import * as fromGPTReducer from './reducers/gpt.reducer';
import * as fromUserReducer from './reducers/user.reducer';
import * as fromMoneyReducer from './reducers/money.reducer';
import * as fromUploadReducer from './reducers/upload.reducer';
import * as fromLookupsReducer from './reducers/lookups.reducer';
import * as fromGeneralReducer from './reducers/general.reducer';
import * as fromChatbotReducer from './reducers/chatbot.reducer';
import * as fromFeedbackReducer from './reducers/feedback.reducer';

export interface CoreState {
  [featureKeys.gptFeatureKey]: fromGPTReducer.State;
  [featureKeys.userFeatureKey]: fromUserReducer.State;
  [featureKeys.moneyFeatureKey]: fromMoneyReducer.State;
  [featureKeys.uploadFeatureKey]: fromUploadReducer.State;
  [featureKeys.lookupsFeatureKey]: fromLookupsReducer.State;
  [featureKeys.generalFeatureKey]: fromGeneralReducer.State;
  [featureKeys.chatbotFeatureKey]: fromChatbotReducer.State;
  [featureKeys.feedbackFeatureKey]: fromFeedbackReducer.State;
}

export function reducers(state: CoreState, action: Action) {
  return combineReducers({
    [featureKeys.gptFeatureKey]: fromGPTReducer.reducer,
    [featureKeys.userFeatureKey]: fromUserReducer.reducer,
    [featureKeys.moneyFeatureKey]: fromMoneyReducer.reducer,
    [featureKeys.uploadFeatureKey]: fromUploadReducer.reducer,
    [featureKeys.lookupsFeatureKey]: fromLookupsReducer.reducer,
    [featureKeys.generalFeatureKey]: fromGeneralReducer.reducer,
    [featureKeys.chatbotFeatureKey]: fromChatbotReducer.reducer,
    [featureKeys.feedbackFeatureKey]: fromFeedbackReducer.reducer,
  })(state, action);
}

// Core selectors
export const selectCoreState = createFeatureSelector<fromRoot.State, CoreState>(
  featureKeys.coreFeatureKey
);

export const selectUserState = createSelector(
  selectCoreState,
  state => state[featureKeys.userFeatureKey]
);

export const selectUploadState = createSelector(
  selectCoreState,
  state => state[featureKeys.uploadFeatureKey]
);

export const selectMoneyState = createSelector(
  selectCoreState,
  state => state[featureKeys.moneyFeatureKey]
);

export const selectGeneralState = createSelector(
  selectCoreState,
  state => state[featureKeys.generalFeatureKey]
);

export const selectGPTState = createSelector(
  selectCoreState,
  state => state[featureKeys.gptFeatureKey]
);

export const selectChatbotState = createSelector(
  selectCoreState,
  state => state[featureKeys.chatbotFeatureKey]
);

export const selectLookupsState = createSelector(
  selectCoreState,
  state => state[featureKeys.lookupsFeatureKey]
);

export const selectFeedbackState = createSelector(
  selectCoreState,
  state => state[featureKeys.feedbackFeatureKey]
);

// User
export const selectIsSignUp = createSelector(
  selectUserState,
  fromUserReducer.selectIsSignUp
);

export const selectRegisterStep = createSelector(
  selectUserState,
  fromUserReducer.selectRegisterStep
);

export const selectRegisterEmail = createSelector(
  selectUserState,
  fromUserReducer.selectRegisterEmail
);

export const selectRegisterUserType = createSelector(
  selectUserState,
  fromUserReducer.selectRegisterUserType
);

export const selectIsSignIn = createSelector(
  selectUserState,
  fromUserReducer.selectIsSignIn
);

export const selectIsSocialSignIn = createSelector(
  selectUserState,
  fromUserReducer.selectIsSocialSignIn
);

export const selectIsOpenSignInModal = createSelector(
  selectUserState,
  fromUserReducer.selectIsOpenSignInModal
);

export const selectSignInSubHeading = createSelector(
  selectUserState,
  fromUserReducer.selectSignInSubHeading
);

export const selectIsOpenSignUpModal = createSelector(
  selectUserState,
  fromUserReducer.selectIsOpenSignUpModal
);

export const selectSignUpTitle = createSelector(
  selectUserState,
  fromUserReducer.selectSignUpTitle
);

export const selectReturnUrl = createSelector(
  selectUserState,
  fromUserReducer.selectReturnUrl
);

export const selectAffiliate = createSelector(
  selectUserState,
  fromUserReducer.selectAffiliate
);

export const selectTutorUID = createSelector(
  selectUserState,
  fromUserReducer.selectTutorUID
);

export const selectToken = createSelector(
  selectUserState,
  fromUserReducer.selectToken
);

export const selectTempToken = createSelector(
  selectUserState,
  fromUserReducer.selectTempToken
);

export const selectIsSignNewsLetter = createSelector(
  selectUserState,
  fromUserReducer.selectIsSignNewsLetter
);

export const selectIsVerifyNewsLetter = createSelector(
  selectUserState,
  fromUserReducer.selectIsVerifyNewsLetter
);

export const selectVerifyNewsLetterError = createSelector(
  selectUserState,
  fromUserReducer.selectVerifyNewsLetterError
);

export const selectSignInFailure = createSelector(
  selectUserState,
  fromUserReducer.selectSignInFailure
);

export const selectIsSubmitOTPAdmin = createSelector(
  selectUserState,
  fromUserReducer.selectIsSubmitOTPAdmin
);

export const selectIsResendOTPAdmin = createSelector(
  selectUserState,
  fromUserReducer.selectIsResendOTPAdmin
);

export const selectProfileStep = createSelector(
  selectUserState,
  fromUserReducer.selectProfileStep
);

export const selectStudentIsDemo = createSelector(
  selectUserState,
  fromUserReducer.selectStudentIsDemo
);

export const selectUserTimezone = createSelector(
  selectUserState,
  fromUserReducer.selectUserTimezone
);

export const selectUser = createSelector(
  selectUserState,
  fromUserReducer.selectUser
);

export const selectSubscription = createSelector(
  selectUserState,
  fromUserReducer.selectSubscription
);

export const selectIsVerifyEmail = createSelector(
  selectUserState,
  fromUserReducer.selectIsVerifyEmail
);

export const selectIsResendEmailConfirm = createSelector(
  selectUserState,
  fromUserReducer.selectIsResendEmailConfirm
);

export const selectUserGPTRemaingDays = createSelector(
  selectUserState,
  fromUserReducer.selectUserGPTRemaingDays
);

export const selectShowEmailVerificationModal = createSelector(
  selectUserState,
  fromUserReducer.selectShowEmailVerificationModal
);

// Lookups

export const selectIsLoadingUserTypes = createSelector(
  selectLookupsState,
  fromLookupsReducer.selectIsLoadingUserTypes
);

export const selectUserTypes = createSelector(
  selectLookupsState,
  fromLookupsReducer.selectUserTypes
);

export const selectPrograms = createSelector(
  selectLookupsState,
  fromLookupsReducer.selectPrograms
);

export const selectIsLoadingPrograms = createSelector(
  selectLookupsState,
  fromLookupsReducer.selectIsLoadingPrograms
);

export const selectIsLoadingProgramCountries = createSelector(
  selectLookupsState,
  fromLookupsReducer.selectIsLoadingProgramCountries
);

export const selectProgramCountries = createSelector(
  selectLookupsState,
  fromLookupsReducer.selectProgramCountries
);

export const selectFilteredProgramCountries = createSelector(
  selectLookupsState,
  fromLookupsReducer.selectFilteredProgramCountries
);

// Feedback

export const selectFeedbackOptions = createSelector(
  selectFeedbackState,
  fromFeedbackReducer.selectFeedbackOptions
);

export const selectIsLoadingFeedbackOptions = createSelector(
  selectFeedbackState,
  fromFeedbackReducer.selectIsLoadingFeedbackOptions
);

// Upload files

export const selectIsUploadingGPTFile = createSelector(
  selectUploadState,
  fromUploadReducer.selectIsUploadingGPTFile
);

export const selectUploadedGPTFiles = createSelector(
  selectUploadState,
  fromUploadReducer.selectUploadedGPTFiles
);

// Money Selectors

export const selectIsLoadingCurrencyRates = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectIsLoadingCurrencyRates
);

export const selectCurrencyRates = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectCurrencyRates
);

export const selectBaseCurrency = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectBaseCurrency
);

export const selectCurrentCurrency = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectCurrentCurrency
);

export const selectLoadingRatesErrorMessage = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectLoadingRatesErrorMessage
);

export const selectCurrenciesNames = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectCurrencies
);

export const selectIsLoadingCurrenciesNames = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectIsLoadingCurrencies
);

export const selectLoadingCurrenciesNamesError = createSelector(
  selectMoneyState,
  fromMoneyReducer.selectLoadingCurrenciesErrorMessage
);

// General Selectors

export const selectWebsitePaddingTop = createSelector(
  selectGeneralState,
  fromGeneralReducer.selectWebsitePaddingTop
);

export const selectSuccessModal = createSelector(
  selectGeneralState,
  fromGeneralReducer.selectSuccessModal
);

export const selectIsOpenLanguageCurrencyModal = createSelector(
  selectGeneralState,
  fromGeneralReducer.selectIsOpenLanguageCurrencyModal
);

// Chatbot Selectors

export const selectIsOpenChatbot = createSelector(
  selectChatbotState,
  fromChatbotReducer.selectIsOpenChatbot
);

export const selectIsLoadingChatbot = createSelector(
  selectChatbotState,
  fromChatbotReducer.selectIsLoadingChatbot
);

export const selectChatbotSteps = createSelector(
  selectChatbotState,
  fromChatbotReducer.selectChatbotSteps
);

// GPT Selectors

export const selectIsAnswerGPT = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsAnswerGPT
);

export const selectGPTAnswer = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTAnswer
);

export const selectIsRecordVoice = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsRecordVoice
);

export const selectRecordedVoice = createSelector(
  selectGPTState,
  fromGPTReducer.selectRecordedVoice
);

export const selectGPTThreadId = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTThreadId
);

export const selectGPTQuestions = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTQuestions
);

export const selectGPTSubject = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTSubject
);

export const selectGPTRemainingQueries = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTRemainingQueries
);

export const selectIsSubscribeGPTPackage = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsSubscribeGPTPackage
);

export const selectIsUnsubscribeGPTPackage = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsUnsubscribeGPTPackage
);

export const selectIsUpgradeGPTPackage = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsUpgradeGPTPackage
);

export const selectIsRefillGPTPackage = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsRefillGPTPackage
);

export const selectGPTPaymentInfo = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTPaymentInfo
);

export const selectGPTHistory = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTHistory
);

export const selectIsLoadingGPTHistory = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsLoadingGPTHistory
);

export const selectIsGettingFreeTrial = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsGettingFreeTrial
);

export const selectPlans = createSelector(
  selectGPTState,
  fromGPTReducer.selectPlans
);

export const selectIsLoadingPlans = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsLoadingPlans
);

export const selectAnnualDiscount = createSelector(
  selectGPTState,
  fromGPTReducer.selectAnnualDiscount
);

export const selectSubscriptions = createSelector(
  selectGPTState,
  fromGPTReducer.selectSubscriptions
);

export const selectSubscriptionsCounts = createSelector(
  selectGPTState,
  fromGPTReducer.selectSubscriptionsCounts
);

export const selectIsLoadingSubscriptions = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsLoadingSubscriptions
);

export const selectIsGiveGPTFeedback = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsGiveGPTFeedback
);

export const selectIsLoadingGPTFeedbacks = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsLoadingGPTFeedbacks
);

export const selectGPTFeedbacksCounts = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTFeedbacksCounts
);

export const selectGPTFeedbacks = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTFeedbacks
);

export const selectIsLoadingGPTFeedbackById = createSelector(
  selectGPTState,
  fromGPTReducer.selectIsLoadingGPTFeedbackById
);

export const selectGPTFeedbackById = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTFeedbackById
);

export const selectGPTSwipeHistoryRight = createSelector(
  selectGPTState,
  fromGPTReducer.selectGPTSwipeHistoryRight
);
