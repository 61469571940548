import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  apiUrl = environment.API_URL;
  baseUrl = environment.chatbotURL;

  constructor(private _http: HttpClient) {}

  promptChatbot(body: any): Observable<any> {
    return this._http
      .post<{ message: any }>(`${this.baseUrl}chat`, body)
      .pipe(map(response => response?.message?.content))
      .pipe(catchError(this.errorHandler));
  }

  submitChatbot(body: any): Observable<any> {
    return this._http.post<any>(`${this.apiUrl}chatbot`, body);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
