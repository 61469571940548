<nav class="navbar navbar-expand-xl text-16 d-none d-xl-block header">
  <div class="container">
    <div class="d-flex flex-grow-1 mobile_menu">
      <span class="w-100 d-lg-none d-block"></span>
      <a class="navbar-brand-two" routerLink="/">
        <img alt="logo" src="assets/logo/logo-dark.png" width="200px" draggable="false">
      </a>
    </div>
  </div>
</nav>


<div class="container body">
  <div class="d-flex flex-column justify-content-center w-75 m-auto">
    <img src="assets/svg/maintenance.svg" alt="" width="435" class="m-auto">
    <h3>We will get back to you</h3>
    <h5>System under maintenance</h5>
    <p>
      Dear valued user, we are currently performing essential maintenance on our system to ensure optimal performance
      and
      improve the user experience. As a result, this may cause some temporary disruption to our services. We apologize
      for
      any inconvenience this may cause and appreciate your patience and understanding during this time. Our team is
      working diligently to minimize any downtime and restore full functionality as quickly as possible. Thank you for
      your cooperation and ongoing support as we work to make our system even better.
    </p>
  </div>
</div>

<div class="footer">
  <div class="container">
    <div class="copyright d-flex justify-content-center">
      <bdi> © {{ today | date: "y" }} {{ 'METUTORS' | translate }}. {{ 'ALL_RIGHTS_RESERVED' | translate }}</bdi>
    </div>
  </div>
</div>
