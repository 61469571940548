import { of, tap } from 'rxjs';
import { UploadService } from '@services';
import { Injectable } from '@angular/core';
import * as uploadActions from '../actions/upload.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlertNotificationService } from '@metutors/core/components';

@Injectable()
export class UploadEffects {
  uploadGPTFile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(uploadActions.uploadGPTFile),
      mergeMap(({ file }) =>
        this._uploadService.UploadOpenAIFile(file).pipe(
          map(response =>
            uploadActions.uploadGPTFileSuccess({
              uploadedFile: response,
            })
          ),
          catchError(error =>
            of(
              uploadActions.uploadGPTFileFailure({
                error:
                  error?.error?.error?.message ||
                  error?.error?.message ||
                  error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  failureMessages$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(...[uploadActions.uploadGPTFileFailure]),
        map(action => {
          if (action.error) {
            return this._alertNotificationService.error(action.error);
          } else {
            return this._alertNotificationService.error('SOMETHING_WENT_WRONG');
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private _actions$: Actions,
    private _uploadService: UploadService,
    private _alertNotificationService: AlertNotificationService
  ) {}
}
