import { createReducer, on } from '@ngrx/store';
import * as gptActions from '../actions/gpt.actions';
import * as uploadActions from '../actions/upload.actions';

export interface State {
  gptFiles: any[];
  isUploadingGPTFile: boolean;
}

export const initialState: State = {
  gptFiles: [],
  isUploadingGPTFile: false,
};

export const reducer = createReducer(
  initialState,

  // upload GPT File
  on(uploadActions.uploadGPTFile, state => ({
    ...state,
    isUploadingGPTFile: true,
  })),

  on(uploadActions.uploadGPTFileSuccess, (state, { uploadedFile }) => ({
    ...state,
    isUploadingGPTFile: false,
    gptFiles: [{ ...uploadedFile }, ...state.gptFiles],
  })),

  on(uploadActions.uploadGPTFileFailure, state => ({
    ...state,
    isUploadingGPTFile: false,
  })),

  on(uploadActions.deleteGPTFile, (state, { id }) => ({
    ...state,
    gptFiles:
      state.gptFiles && state.gptFiles?.length
        ? state.gptFiles.filter(file => file.id !== id)
        : [],
  })),

  on(uploadActions.resetGPTFiles, gptActions.answerGPT, state => ({
    ...state,
    gptFiles: [],
    isUploadingGPTFile: false,
  })),
);

export const selectIsUploadingGPTFile = (state: State): boolean =>
  state.isUploadingGPTFile;

export const selectUploadedGPTFiles = (state: State): any[] => state.gptFiles;
