<div class="item">
  <div class="ph-row">
    <div class="ph-col-12 big mt-1"></div>
    <div class="ph-col-0 big empty"></div>
    <div class="ph-col-12 big mt-1"></div>
    <div class="ph-col-0 big empty mt-1"></div>
    <div class="ph-col-12 big mt-1"></div>
    <div class="ph-col-0 big empty mt-1"></div>
    <div class="ph-col-12 big mt-1"></div>
    <div class="ph-col-0 big empty mt-1"></div>
    <div class="ph-col-12 big mt-1"></div>
    <div class="ph-col-0 big empty mt-1"></div>
  </div>
</div>
