<div class="alert-item" [@enterLeave]="item.state" [class]="theClass" [ngClass]="{
        'alert': item.type === 'alert',
        'error': item.type === 'error',
        'success': item.type === 'success',
        'info': item.type === 'info',
        'bare': item.type === 'bare',
        'rtl-mode': rtl
    }">

  <div *ngIf="!item.html">
    <div class="sn-title"><bdi>{{ item?.title! | translate }}</bdi></div>
    <div class="sn-content"><bdi>{{ item?.content | max:maxLength }}</bdi></div>
    <div class="icon" (mouseenter)="onEnter()" (mouseleave)="onLeave()" (click)="onClick($event)">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 7L17 17" stroke="#CEDAE1" stroke-width="2" stroke-linecap="round" />
        <path d="M17 7L7 17" stroke="#CEDAE1" stroke-width="2" stroke-linecap="round" />
      </svg>
    </div>
  </div>

  <div *ngIf="item.html" [innerHTML]="item.html"></div>

  <div class="sn-progress-loader" *ngIf="showProgressBar">
    <span [ngStyle]="{'width': progressWidth + '%'}"></span>
  </div>
</div>
