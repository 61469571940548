<form [formGroup]="signinForm" (ngSubmit)="onSubmit(signinForm)" class="w-100 d-flex align-items-center signin-form">
  <div class="w-100">
    <h4 class="mb-1">{{ 'LOGIN' | translate }}</h4>
    <h5 class="mb-4">{{ 'ENTER_CREDENTIALS_LOGIN_ACCOUNT' | translate }}</h5>

    <div class="form-group">
      <label for="email">{{ 'EMAIL' | translate }}</label>
      <input type="email" class="auth-input w-100 input-bordered" id="email"
             placeholder="{{ 'ENTER_UR_EMAIL' | translate }}" formControlName="username">
      <div *ngIf="username?.invalid && username?.touched">
        <small class="text-error">{{ 'WRITE_VALID_EMAIL' | translate }}</small>
      </div>
    </div>

    <div class="form-group">
      <label for="pass">{{ 'PASSWORD' | translate }}</label>
      <div class="position-relative">
        <input [type]="!passwordVisibility ? 'password' : 'text'" class="auth-input w-100 input-bordered"
               id="pass" placeholder="{{ 'ENTER_YOUR_PASSWORD' | translate }}" formControlName="password">
        <mat-icon matSuffix class="eye" (click)="passwordVisibility = !passwordVisibility">
          {{ !passwordVisibility ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <span class="text-error" *ngIf="password?.invalid && password?.touched && password?.hasError('passwordError')">
          {{ password?.errors?.['passwordError'] | translate }}
        </span>
      </div>
    </div>

    <div class="form-group d-flex justify-content-between">
      <mat-checkbox formControlName="rememberMe">{{ 'REMEMBER_ME' | translate }}</mat-checkbox>
      <a [attr.href]="clientUrl + '/forget-password'" target="_blank" class="forget-link" (click)="changeRouter.emit()">{{ 'FORGET_PASSWORD' | translate }}</a>
    </div>

    <div class="form-group mt-4 mb-2">
      <metutors-submit-button [form]="signinForm" [isSubmitting]="isLoading" [extraClasses]="'w-100 rounded-btn'">
        {{ 'LOGIN' | translate }}
      </metutors-submit-button>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="or fw-bold text-center text-uppercase">{{ 'OR' | translate }}</div>
      </div>
    </div>

    <div class="row mt-4 social">
      <div class="col-xl-6 mt-1">
        <button (click)="openRolesDialog.emit('google')" mat-button type="button" class="w-100 google rounded-btn"
                [disabled]="authLoading">
          <img src="assets/svg/google.svg" alt="" class="me-2">
          {{ 'GOOGLE' | translate }}
        </button>
      </div>
      <div class="col-xl-6 mt-1">
        <button (click)="openRolesDialog.emit('facebook')" mat-button type="button" class="w-100 facebook rounded-btn"
                [disabled]="authLoading">
          <img src="assets/svg/facebook-circle.svg" class="me-2" alt="">
          {{ 'FACEBOOK' | translate }}
        </button>
      </div>
    </div>

    <div class="form-group mt-4">
      <p>
        {{ 'DONT_HAVE_ACCOUNT' | translate }}
        <a [attr.href]="clientUrl + '/signup?root=metutorsAI&affiliate=' + affiliate"
           class="ms-2" (click)="changeRouter.emit()">
          {{ 'SIGNUP_NOW' | translate }}
        </a>
      </p>
      <p [innerHTML]="'OUR_PRIVACY_INCLUDE_INFO' | translate" class="privacy"></p>
    </div>
  </div>
</form>
