import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '@environment';
import * as fromCore from '@metutors/core/state';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'metutors-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isSignupNewsLetter$: Observable<boolean>;

  form: FormGroup;
  myDate = new Date();
  clientUrl = environment.clientUrl;

  constructor(private _store: Store<any>, private _fb: FormBuilder) {}

  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const email = this.form.value;
    this._store.dispatch(fromCore.signupNewsLetter(email));
    this.form.reset();
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      email: [null, [Validators.required, Validators.email]],
    });

    this.isSignupNewsLetter$ = this._store.select(
      fromCore.selectIsSignNewsLetter
    );
  }
}
