import { environment } from '@environment';
import { IRole } from '@metutors/core/models';
import { ActivatedRoute } from '@angular/router';
import { FormValidationUtilsService } from '@metutors/core/validators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'metutors-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss'],
})
export class SigninFormComponent implements OnInit {
  @Input() roles: IRole[];
  @Input() affiliate: string;
  @Input() isLoading: boolean;
  @Input() authLoading: boolean;

  @Output() signIn = new EventEmitter();
  @Output() changeRouter = new EventEmitter();
  @Output() signupClicked = new EventEmitter();
  @Output() openRolesDialog = new EventEmitter();

  returnUrl: string;
  signinForm: FormGroup;
  passwordVisibility = false;
  clientUrl = environment.clientUrl;

  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _fv: FormValidationUtilsService
  ) {
    this.signinForm = this._fb.group({
      username: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: [null, [Validators.required, this._fv.minPasswordValidation]],
      rememberMe: [false],
    });
  }

  ngOnInit(): void {
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'];
  }

  get username(): AbstractControl | null {
    return this.signinForm.get('username');
  }

  get password(): AbstractControl | null {
    return this.signinForm.get('password');
  }

  onSubmit(form: FormGroup): void {
    if (form.invalid) {
      return;
    }

    this.signIn.emit(form.value);
  }
}
