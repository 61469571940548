<div>
  <h4 class="mb-4 d-flex align-items-center justify-content-between">
    <span>{{ 'ROLE_SELECTION' | translate }}</span>
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h4>
  <div class="form-group types">
    <div class="row">
      <div class="col-md-6" *ngFor="let role of roles">
        <button mat-button (click)="onSubmit(role.id)" class="w-100 text-start" type="button" [disabled]="role.id === userRole.tutor">
          <svg *ngIf="role?.id === userRole.student" xmlns="http://www.w3.org/2000/svg" id="_001-reading-book" data-name="001-reading-book" width="24" height="28.577" viewBox="0 0 24 28.577">
            <circle id="Oval" cx="4.744" cy="4.744" r="4.744" transform="translate(7.256 0)" fill="#3bb3c1"/>
            <path id="Path" d="M10.007,0A6.381,6.381,0,0,1,6.521,1.032,6.381,6.381,0,0,1,3.035,0,9.758,9.758,0,0,0,0,1.854L6.521,4.3l6.521-2.445A9.757,9.757,0,0,0,10.007,0Z" transform="translate(5.479 10.131)" fill="#3bb3c1"/>
            <path id="Path-2" data-name="Path" d="M1.4,0H.837A.837.837,0,0,0,0,.837V3.07a.837.837,0,0,0,.837.837H1.4A1.953,1.953,0,0,0,1.4,0Z" transform="translate(20.651 17.302)" fill="#3bb3c1"/>
            <path id="Path-3" data-name="Path" d="M.36.149A.837.837,0,0,0,0,.837V2.7H.279A2.514,2.514,0,0,1,2.791,5.213V7.445A2.514,2.514,0,0,1,.279,9.957H0v1.764a.837.837,0,0,0,.543.784L8.93,15.65V2.978L1.131.053A.837.837,0,0,0,.36.149Z" transform="translate(2.233 12.927)" fill="#3bb3c1"/>
            <path id="Path-4" data-name="Path" d="M6.14,7.445V5.213A2.514,2.514,0,0,1,8.651,2.7H8.93V.837A.837.837,0,0,0,7.8.053L0,2.978V15.65L8.387,12.5a.837.837,0,0,0,.543-.784V9.957H8.651A2.514,2.514,0,0,1,6.14,7.445Z" transform="translate(12.837 12.927)" fill="#3bb3c1"/>
            <path id="Path-5" data-name="Path" d="M3.349,3.07V.837A.837.837,0,0,0,2.512,0H1.953a1.953,1.953,0,0,0,0,3.907h.558A.837.837,0,0,0,3.349,3.07Z" transform="translate(0 17.302)" fill="#3bb3c1"/>
          </svg>
          <svg *ngIf="role?.id === userRole.tutor" xmlns="http://www.w3.org/2000/svg" width="23.962" height="24.875" viewBox="0 0 23.962 24.875">
            <g id="_002-teacher" data-name="002-teacher" transform="translate(-0.019)">
              <path id="Path" d="M16.038,0H.108A2.822,2.822,0,0,1,.763,1.811,2.824,2.824,0,0,1,0,3.743a3.019,3.019,0,0,1,2.471,1.9l.536-.908a1.784,1.784,0,0,1,1.53-.873,1.764,1.764,0,0,1,.281.023l.164-.875a.552.552,0,0,1,1.084.2c-.23,1.229-.216,1.162-.231,1.213a1.776,1.776,0,0,1,.23,2.114L3.48,10.917a1.776,1.776,0,0,1-2.137.766v.968H16.038a.687.687,0,0,0,.687-.687V.687A.687.687,0,0,0,16.038,0Z" transform="translate(7.256 0.237)"/>
              <path id="Path-2" data-name="Path" d="M12.276.259a.988.988,0,0,0-1.353.349L9.481,3.05,9.04,1.589A2.23,2.23,0,0,0,6.882,0H2.953A2.253,2.253,0,0,0,.765,1.8L.022,5.286a.988.988,0,0,0,.79,1.178l3.222.584A.988.988,0,0,0,4.386,5.1l-2.22-.4L2.56,2.856V3.908l1.979.359a1.839,1.839,0,1,1-.656,3.618l-1.333-.24V18.929a1.18,1.18,0,1,0,2.36,0V10.242h.512v8.687a1.185,1.185,0,0,0,2.371,0V4.289l.451,1.489a.988.988,0,0,0,1.8.216l2.586-4.381A.988.988,0,0,0,12.276.259Z" transform="translate(0.019 4.76)"/>
              <path id="Path-3" data-name="Path" d="M2.047,0A2.047,2.047,0,1,0,4.095,2.047,2.047,2.047,0,0,0,2.047,0Z" transform="translate(3.137)"/>
            </g>
          </svg>
          <span class="d-inline-block grey-hint">
            {{ 'IM_A' | translate }} {{ (role?.name)! + '_SM' | uppercase | translate }}
            <span class="d-block" *ngIf="role?.id === userRole.tutor">({{ 'CURRENTLY_UNAVAILABLE' | translate }})</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
