import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IRole } from '@metutors/core/models';
import * as fromCore from '@metutors/core/state';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@metutors/core/services';
import { Affiliate, SocialProvider } from '@metutors/config';
import { RolesSelectComponent } from '@metutors/shared/components';
import { Input, OnInit, Output, Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'metutors-signin-modal',
  templateUrl: './signin-modal.component.html',
  styleUrls: ['./signin-modal.component.scss'],
})
export class SigninModalComponent implements OnInit {
  roles$: Observable<IRole[]>;
  userRole$: Observable<number>;
  isLoading$: Observable<boolean>;
  authLoading$: Observable<boolean>;

  @Input() returnUrl: string;
  @Input() subHeading: string;
  @Input() showModal: boolean = false;

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() signupClicked: EventEmitter<any> = new EventEmitter<any>();

  userRole: any;
  affiliate: string;

  constructor(
    private _store: Store<any>,
    private _dialog: MatDialog,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._prepareRoles();
    this.affiliate = Affiliate.gpt;
    this.isLoading$ = this._store.select(fromCore.selectIsSignIn);
    this.authLoading$ = this._store.select(fromCore.selectIsSocialSignIn);
  }

  onChangeRouter(): void {
    this._store.dispatch(fromCore.routeSignInModal());
  }

  openRolesDialog(domain: any, roles: IRole[], affiliate: string): void {
    const dialogRef = this._dialog.open(RolesSelectComponent, {
      width: '500px',
      disableClose: true,
      data: roles,
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res && res.data) {
        this.userRole = res.data.toString();
        domain === 'google'
          ? this.signInWithGoogle(affiliate)
          : this.signInWithFacebook(affiliate);
      }
    });
  }

  signInWithGoogle(affiliate: string) {
    this._authService
      .signInWithGoogle()
      .then((response: any) => {
        const data = {
          ...response,
          affiliate,
          role: this.userRole,
          provider: SocialProvider.google,
        };

        this._store.dispatch(
          fromCore.socialSignIn({ user: data, returnUrl: this.returnUrl })
        );
      })
      .catch((error: any) => console.log('Error =>', error));
  }

  signInWithFacebook(affiliate: string) {
    this._authService.signInWithFacebook().then(response => {
      const data = {
        ...response,
        affiliate,
        role: this.userRole,
        provider: SocialProvider.facebook,
      };

      this._store.dispatch(
        fromCore.socialSignIn({ user: data, returnUrl: this.returnUrl })
      );
    });
  }

  onSubmit(user: any): void {
    this._store.dispatch(fromCore.signIn({ user, returnUrl: this.returnUrl }));
  }

  private _prepareRoles(): void {
    this._store.dispatch(fromCore.loadUserTypes());
    this.roles$ = this._store.select(fromCore.selectUserTypes);
  }
}
