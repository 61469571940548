<metutors-modal [showModal]="showModal" (closeModal)="closeModal.emit()"
                [size]="subHeading === 'GPT_MODAL' ? 'large' : 'medium'"
                [subHeading]="subHeading !== 'GPT_MODAL' ? subHeading : ''"
                [template]="template">
  <ng-template #template>
    <ng-container *ngLet="(roles$ | async)! as roles">
      <div class="row">
        <div class="col-md-6 position-relative order-2-sm" *ngIf="subHeading === 'GPT_MODAL'">
          <div class="hints position-sticky top-0 pe-4">
            <h2>{{ 'GET_RELIABLE_ANSWERS_HOMEWORK_PROBLEMS' | translate }}</h2>
            <ul class="p-0 mt-4 mb-0">
              <li><span class="ms-4" [innerHTML]="'GET_INSTANT_STEP_SOLUTIONS' | translate"></span></li>
              <li><span class="ms-4" [innerHTML]="'FREE_ACCESS_PREMIUM_GPTS_30_DAYS' | translate"></span></li>
              <li><span class="ms-4" [innerHTML]="'YOU_GOT_SUBJECT_SPECIFIC_GPTS' | translate"></span></li>
              <li><span class="ms-4" [innerHTML]="'TEST_PREP_GPTS_ETCS' | translate"></span></li>
              <li><span class="ms-4" [innerHTML]="'TRANSFORM_LENGTHLY_VIDEOS_CONCISE_INSIGHTS_GPT' | translate"></span></li>
            </ul>
          </div>
        </div>
        <div class="col order-1-sm">
          <metutors-signin-form [isLoading]="(isLoading$ | async)!" 
                                [roles]="(roles$ | async)!"
                                [authLoading]="(authLoading$ | async)!"
                                [affiliate]="affiliate"
                                (signIn)="onSubmit($event)"
                                (changeRouter)="onChangeRouter()"
                                (openRolesDialog)="openRolesDialog($event, roles, affiliate)">
          </metutors-signin-form>
        </div>
      </div>

      <span class="hint position-absolute bottom-0 left-0 w-100-sm" [innerHTML]="'POWERED_BY_OPEN_AI' | translate" *ngIf="subHeading === 'GPT_MODAL'"></span>
    </ng-container>
  </ng-template>
</metutors-modal>
