import { createAction, props } from '@ngrx/store';

export const uploadGPTFile = createAction(
  '[Upload] Upload GPT File',
  props<{ file: File }>()
);

export const uploadGPTFileSuccess = createAction(
  '[Upload] Upload GPT File Success',
  props<{ uploadedFile: any }>()
);

export const uploadGPTFileFailure = createAction(
  '[Upload] Upload GPT File Failure',
  props<{ error: any }>()
);

export const deleteGPTFile = createAction(
  '[Upload] Delete GPT File',
  props<{ id: string }>()
);

export const resetGPTFiles = createAction('[Upload] Reset GPT Files');
