import { createReducer, on } from '@ngrx/store';
import * as feedbackActions from '../actions/feedback.actions';

export interface State {
  feedbackOptions: any;
  isLoadingFeedbackOptions: boolean;
}

export const initialState: State = {
  feedbackOptions: null,
  isLoadingFeedbackOptions: false,
};

export const reducer = createReducer(
  initialState,

  on(feedbackActions.loadGptFeedbackOptions, state => ({
    ...state,
    isLoadingFeedbackOptions: true,
  })),

  on(
    feedbackActions.loadGptFeedbackOptionsSuccess,
    (state, { feedbackOptions }) => ({
      ...state,
      feedbackOptions,
      isLoadingFeedbackOptions: false,
    })
  ),

  on(feedbackActions.loadGptFeedbackOptionsFailure, state => ({
    ...state,
    isLoadingFeedbackOptions: false,
  }))
);

export const selectFeedbackOptions = (state: State): any =>
  state.feedbackOptions;

export const selectIsLoadingFeedbackOptions = (state: State): boolean =>
  state.isLoadingFeedbackOptions;
