<nav class="navbar navbar-expand-xl text-16 d-none d-xl-block home-header shadow-bottom" #navbar *ngIf="!url.includes('/') || url === '/full'">
  <div class="container">
    <a class="navbar-brand-two" routerLink="/">
      <img alt="logo" src="assets/logo/logo-gpt.png" width="200px" draggable="false">
    </a>
    <div class="flex-grow-1">
      <ul class="navbar-nav ms-auto d-flex flex-row p-0">
        <div class="flex-grow-1 d-flex align-items-center justify-content-center px-3">
          <li class="nav-item d-flex align-items-center justify-content-center">
            <a class="nav-link nav-item-link" [matMenuTriggerFor]="services">
              <span class="lang">{{ 'OUR_SERVICES' | translate }}</span>
              <svg-icon name="b-chevron" [applyClass]="true" class="b-chevron"></svg-icon>
            </a>
          </li>
          <li class="nav-item d-flex align-items-center justify-content-center">
            <a class="nav-link nav-item-link" [matMenuTriggerFor]="programs">
              <span class="lang">{{ 'PROGRAMS' | translate }}</span>
              <svg-icon name="b-chevron" [applyClass]="true" class="b-chevron"></svg-icon>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-item-link" [attr.href]="clientUrl + '/study'" target="_blank">
              {{ 'STUDY' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-item-link" [attr.href]="clientUrl + '/become-tutor'" target="_blank">
              {{ 'BECOME_TUTOR' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-item-link" [attr.href]="clientUrl + '/about'" target="_blank">
              {{ 'ABOUT_US' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-item-link" (click)="onOpenLanguageCurrencyModal()">
              <svg-icon name="language" [applyClass]="true" class="b-chevron language"></svg-icon>
            </a>
          </li>
        </div>
        <li class="nav-item btn-nav d-flex align-items-center">
          <ng-container *ngIf="!(token$ | async)!">
            <a class="link-btn login px-4 py-1 fw-bold" mat-flat-button color="primary"
               (click)="openSignInModal('/full')">
              {{ 'SIGN_IN' | translate }}
            </a>
          </ng-container>
          <div [matMenuTriggerFor]="userProfile" class="user-nav" *ngIf="(token$ | async)!">
            <img [src]="(user$ | async)?.avatar" meTutorsDefaultAvatar alt="" class="rounded-circle me-2" draggable="false" width="30"
                 height="30" [accesskey]="(user$ | async)?.gender" />
            <span>{{ (user$ | async)?.firstName }}</span>
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.007 13.5C11.8637 13.5 11.7293 13.4778 11.6039 13.4333C11.4785 13.3889 11.362 13.3133 11.2545 13.2067L6.28277 8.27377C6.08569 8.07823 5.99163 7.8338 6.00058 7.54049C6.00954 7.24718 6.11256 7.00276 6.30964 6.80722C6.50672 6.61168 6.75755 6.51391 7.06212 6.51391C7.3667 6.51391 7.61753 6.61168 7.81461 6.80722L12.007 10.9669L16.2263 6.78056C16.4234 6.58502 16.6697 6.49169 16.9653 6.50058C17.261 6.50947 17.5073 6.61168 17.7044 6.80722C17.9015 7.00276 18 7.25163 18 7.55383C18 7.85602 17.9015 8.10489 17.7044 8.30043L12.7595 13.2067C12.652 13.3133 12.5355 13.3889 12.4101 13.4333C12.2847 13.4778 12.1503 13.5 12.007 13.5Z" fill="#929094"/>
            </svg>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<nav class="navbar navbar-expand-xl navbar-light py-3 card d-block d-xl-none home-header-sm shadow-sm"
     data-navbar-on-scroll="data-navbar-on-scroll" #navbarSmall>
  <div class="container" dropdown #dropdown="bs-dropdown" [autoClose]="false">
    <a class="navbar-brand" routerLink="/">
      <img alt="logo" src="assets/logo/logo-gpt.png" width="146px" draggable="false">
    </a>
    <button mat-button class="collapsed" type="button" data-bs-toggle="collapse" dropdownToggle
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11.6347V10.1347H17V11.6347H0ZM0 6.75005V5.2501H17V6.75005H0ZM0 1.86545V0.365479H17V1.86545H0Z" fill="#939393"/>
      </svg>
    </button>

    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-left w-100-md-sm text-start" role="menu"
        aria-labelledby="button-basic">
      <li role="menuitem">
        <a class="dropdown-item text-start" (click)="selectedMenu = (selectedMenu === 'OUR_SERVICES' ? '' : 'OUR_SERVICES')">
          <span class="me-2 text-capitalize">{{ 'OUR_SERVICES' | translate }}</span>
          <img src="assets/svg/b-chevron.svg" alt="" draggable="false">
        </a>
      </li>
      <div [@slideInOut] class="menu-list" *ngIf="selectedMenu === 'OUR_SERVICES'">
        <a mat-menu-item class="h-auto" *ngIf="!(token$ | async)!"
           [attr.href]="clientUrl + '/explore-tutors'" target="_blank" (click)="dropdown.hide(); selectedMenu = ''">
          {{ 'PERSONALIZED_TUTORING' | translate }}
        </a>
        <a mat-menu-item class="h-auto" *ngIf="(token$ | async)!"  target="_blank"
           [attr.href]="clientUrl + (+((user$ | async)?.roleId)! === userRole.tutor ? '/tutor' : +((user$ | async)?.roleId)! === userRole.student ? '/student' : '/admin')"
           (click)="dropdown.hide(); selectedMenu = ''">
          {{ 'PERSONALIZED_TUTORING' | translate }}
        </a>
        <a mat-menu-item class="h-auto text-capitalize" [routerLink]="[subscription && +subscription?.planId! ? '/full' : '/']"
           (click)="dropdown.hide(); selectedMenu = ''">
          {{ 'INSTANT_QUESTION_SOLVER' | translate }}
        </a>
      </div>
      <li role="menuitem">
        <a class="dropdown-item text-start" (click)="selectedMenu = (selectedMenu === 'PROGRAMS' ? '' : 'PROGRAMS')">
          <span class="me-2 text-capitalize">{{ 'PROGRAMS' | translate }}</span>
          <img src="assets/svg/b-chevron.svg" alt="" draggable="false">
        </a>
      </li>
      <div [@slideInOut] class="menu-list" *ngIf="selectedMenu === 'PROGRAMS'">
        <a mat-menu-item class="h-auto" *ngFor="let program of (programs$ | async)"
           (click)="onSelectProgram(program.id); dropdown.hide(); selectedMenu = ''">
          <bdi>{{ program?.name }}</bdi>
        </a>
      </div>
      <li role="menuitem">
        <a class="dropdown-item" [attr.href]="clientUrl + '/study'" target="_blank" (click)="dropdown.hide(); selectedMenu = ''">
          {{ 'STUDY' | translate }}
        </a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item" [attr.href]="clientUrl + '/become-tutor'" target="_blank" (click)="dropdown.hide(); selectedMenu = ''">
          {{ 'BECOME_TUTOR' | translate }}
        </a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item" [attr.href]="clientUrl + '/about'" target="_blank" (click)="dropdown.hide(); selectedMenu = ''">
          {{ 'ABOUT_US' | translate }}
        </a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item text-center" (click)="selectedMenu = (selectedMenu === 'CURRENCIES' ? '' : 'CURRENCIES')">
          <span class="me-2">{{ (selectedCurrency$ | async)?.id }}</span>
          <img src="assets/svg/b-chevron.svg" alt="" draggable="false">
        </a>
      </li>
      <div [@slideInOut] class="menu-list" *ngIf="selectedMenu === 'CURRENCIES'">
        <a mat-menu-item class="h-auto" *ngFor="let item of (currencyRates$ | async)" [ngClass]="{
            'active' : item.id === (selectedCurrency$ | async)?.id
           }" (click)="onCurrencySelect(item); dropdown.hide(); selectedMenu = ''">
          <bdi>{{ item?.name }}</bdi>
        </a>
      </div>
      <li role="menuitem">
        <a class="dropdown-item text-center" (click)="selectedMenu = (selectedMenu === 'LANGUAGES' ? '' : 'LANGUAGES')">
          <img src="assets/svg/language.svg" alt="" draggable="false">
          <span class="mx-2 text-capitalize">{{ selectedLanguage }}</span>
          <img src="assets/svg/b-chevron.svg" alt="" draggable="false">
        </a>
      </li>
      <div [@slideInOut] class="menu-list" *ngIf="selectedMenu === 'LANGUAGES'">
        <a mat-menu-item class="h-auto" (click)="onLanguageSelect('ar'); dropdown.hide(); selectedMenu = ''">
          العربية
        </a>
        <a mat-menu-item class="h-auto" (click)="onLanguageSelect('en'); dropdown.hide(); selectedMenu = ''">
          English
        </a>
      </div>

      <li class="divider dropdown-divider"></li>

      <ng-container *ngIf="!(token$ | async)">
        <li role="menuitem" class="px-3 mb-1">
          <a class="main-button-transparent main-btn order-0 text-center w-100"
             mat-button color="primary" [attr.href]="clientUrl + '/signup?affiliate=' + affiliate + '&root=metutorsAI'">
            {{ 'JOIN_METUTORS' | translate }}
          </a>
        </li>
        <li role="menuitem" class="px-3">
          <a class="main-btn order-0 text-center w-100" mat-flat-button color="primary"
             (click)="dropdown.hide(); selectedMenu = ''; openSignInModal('/full')">
            {{ 'SIGN_IN' | translate }}
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="(token$ | async)!">
        <li role="menuitem" class="book-class">
          <a class="main-button-transparent main-btn order-0 text-center w-100" mat-button
             (click)="dropdown.hide(); selectedMenu = ''"
             [attr.href]="clientUrl + (+((user$ | async)?.roleId)! === userRole.tutor ? '/tutor' : +((user$ | async)?.roleId)! === userRole.student ? '/student' : '/admin')">
            {{ ((+((user$ | async)?.roleId)! === userRole.tutor || +((user$ | async)?.roleId)! === userRole.student) ? 'MY_CLASSROOM' : 'CONTROL_PANEL') | translate }}
          </a>
        </li>
        <li role="menuitem">
          <a class="secondary-btn w-100 button-auth text-center" type="button"
             (click)="logout(); dropdown.hide(); selectedMenu = ''">{{ 'LOGOUT' | translate }}</a>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>

<mat-menu #services="matMenu">
  <a mat-menu-item class="h-auto b-bottom-item" *ngIf="!(token$ | async)!"
     [attr.href]="clientUrl + '/explore-tutors'" target="_blank">
    {{ 'PERSONALIZED_TUTORING' | translate }}
  </a>
  <a mat-menu-item class="h-auto b-bottom-item" *ngIf="(token$ | async)!"  target="_blank"
     [attr.href]="clientUrl + (+((user$ | async)?.roleId)! === userRole.tutor ? '/tutor' : +((user$ | async)?.roleId)! === userRole.student ? '/student' : '/admin')">
    {{ 'PERSONALIZED_TUTORING' | translate }}
  </a>
  <a mat-menu-item class="h-auto text-capitalize" [routerLink]="[subscription && +subscription?.planId! ? '/full' : '/']">{{ 'INSTANT_QUESTION_SOLVER' | translate }}</a>
</mat-menu>

<mat-menu #programs="matMenu">
  <div>
    <a mat-menu-item class="h-auto" *ngFor="let program of (programs$ | async)!; let isLast = last"
       (click)="onSelectProgram(program.id)" [class.b-bottom-item]="!isLast">
      <bdi>{{ program?.name }}</bdi>
    </a>
  </div>
</mat-menu>

<mat-menu #userProfile="matMenu" class="mt-2" xPosition="before">
  <a mat-menu-item class="h-auto btn-menu" target="_blank"
     [attr.href]="clientUrl + (+((user$ | async)?.roleId)! === userRole.tutor ? '/tutor' : +((user$ | async)?.roleId)! === userRole.student ? '/student' : '/admin')">
    {{ ((+((user$ | async)?.roleId)! === userRole.tutor || +((user$ | async)?.roleId)! === userRole.student) ? 'MY_CLASSROOM' : 'CONTROL_PANEL') | translate }}
  </a>
  <a mat-menu-item class="h-auto btn-menu text-capitalize"
     [routerLink]="[subscription && +subscription?.planId! ? '/full' : '/']">
    {{ 'MY_METUTORSMATE' | translate }}
  </a>
  <a mat-menu-item class="h-auto btn-menu" target="_blank"
     [attr.href]="clientUrl + (+((user$ | async)?.roleId)! === userRole.tutor ? ('/profile/tutor/' + (user$ | async)?.id)  : +((user$ | async)?.roleId)! === userRole.student ? '/student/settings' : '/admin')">
    <span>{{ 'VIEW_PROFILE' | translate }}</span>
  </a>
  <button mat-menu-item class="h-auto btn-menu" (click)="logout()">
    <span>{{ 'LOGOUT' | translate }}</span>
  </button>
</mat-menu>

<metutors-language-currency-modal *ngIf="(openLanguageCurrencyModal$ | async)!"
                                  [showModal]="(openLanguageCurrencyModal$ | async)!"
                                  [selectedLanguage]="selectedLanguage"
                                  [currencyRates]="(currencyRates$ | async)!"
                                  [selectedCurrency]="(selectedCurrency$ | async)!"
                                  (closeModal)="onCloseLanguageCurrencyModal()"
                                  (languageSelect)="onLanguageSelect($event); onCloseLanguageCurrencyModal()"
                                  (currencySelect)="onCurrencySelect($event); onCloseLanguageCurrencyModal()">
</metutors-language-currency-modal>
