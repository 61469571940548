import { Store } from '@ngrx/store';
import { isNil, omitBy } from 'lodash';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import * as fromRoot from '@metutors/state';
import { SeoSocialShareService } from 'ngx-seo';
import { Meta } from '@angular/platform-browser';
import { tap, withLatestFrom } from 'rxjs/operators';
import * as generalActions from '../actions/general.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';

@Injectable()
export class GeneralEffects {
  setMetaTags$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(generalActions.setMetaTags),
        withLatestFrom(this._store.select(fromRoot.selectUrl)),
        tap(([action, url]) => {
          let seoData: any = {
            title: action?.tags?.title,
            keywords: action?.tags?.keywords,
            description: action?.tags?.description,
            image: action?.tags?.image,
            url: action?.tags?.url || environment.clientUrl + url,
            type: action?.tags?.type,
            section: action?.tags?.section,
            published: action?.tags?.published,
            modified: action?.tags?.modified,
          };

          seoData = omitBy(seoData, isNil);
          this._seoSocialShareService.setData(seoData);

          if (action?.tags?.ogTitle) {
            this._metaService.updateTag({
              property: 'twitter:title',
              content: action?.tags?.ogTitle,
            });

            this._metaService.updateTag({
              property: 'og:title',
              content: action?.tags?.ogTitle,
            });
          }

          if (action?.tags?.firstName) {
            this._metaService.updateTag({
              property: 'profile:first_name',
              content: action?.tags?.firstName,
            });
          }

          if (action?.tags?.lastName) {
            this._metaService.updateTag({
              property: 'profile:last_name',
              content: action?.tags?.lastName,
            });
          }

          if (action?.tags?.username) {
            this._metaService.updateTag({
              property: 'profile:username',
              content: action?.tags?.username,
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private _store: Store<any>,
    private _actions$: Actions,
    private readonly _metaService: Meta,
    private readonly _seoSocialShareService: SeoSocialShareService
  ) {}
}
