import { createReducer, on } from '@ngrx/store';
import * as chatbotActions from '../actions/chatbot.actions';

export interface State {
  chatbotSteps: any[];
  openChatbot: boolean;
  loadingChatbot: boolean;
}

export const initialState: State = {
  openChatbot: false,
  loadingChatbot: false,
  chatbotSteps: [
    {
      number: 1,
      date: new Date(),
      isMetutors: true,
      name: 'Hello there! What is your name?',
    },
  ],
};

export const reducer = createReducer(
  initialState,

  on(chatbotActions.initChatbot, state => ({
    ...state,
    openChatbot: true,
  })),

  on(chatbotActions.closeChatbot, state => ({
    ...state,
    openChatbot: false,
  })),

  on(chatbotActions.resetChatbotStep, state => ({
    ...state,
    chatbotSteps: [],
  })),

  on(chatbotActions.changeChatbotStep, (state, { step }) => ({
    ...state,
    chatbotSteps: [...state.chatbotSteps, { ...step }],
    loadingChatbot: step?.number === 5 ? true : false,
  })),

  on(chatbotActions.changeChatbotStepSuccess, (state, { step }) => ({
    ...state,
    chatbotSteps: [...state.chatbotSteps, { ...step }],
    loadingChatbot: false,
  })),

  on(chatbotActions.changeChatbotStepFailure, state => ({
    ...state,
    loadingChatbot: false,
  }))
);

export const selectIsOpenChatbot = (state: State): boolean => state.openChatbot;

export const selectIsLoadingChatbot = (state: State): boolean =>
  state.loadingChatbot;

export const selectChatbotSteps = (state: State): any[] => state.chatbotSteps;
