import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@metutors/core/state';
import { AlertNotificationService } from '../components';

@Injectable()
export class GPTGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: Store<any>,
    private _alertNotificationService: AlertNotificationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this._store.pipe(
      select(fromCore.selectSubscription),
      map(subscription => {
        if (!subscription || (subscription && !subscription?.planId!)) {
          this._alertNotificationService.error('YOUR_SUBSCRIPTION_EXPIRED');
          this._router.navigateByUrl('/');

          return false;
        }

        return true;
      })
    );
  }
}
