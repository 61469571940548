import { Directive, Input } from '@angular/core';
import { GENDERS, generalConstants } from 'src/app/config';

@Directive({
  selector: '[meTutorsDefaultAvatar]',
  // eslint-disable-next-line @typescript-eslint/tslint/config
  host: {
    '[src]': 'checkPath(src)',
    '(error)': 'onError()',
  },
})
export class DefaultAvatarDirective {
  @Input() src?: string;
  @Input() accesskey?: string;

  maleImg = generalConstants.defaultAvatarPath;
  femaleImg = generalConstants.defaultFemalePath;

  onError(): void {
    this.src = this.accesskey === GENDERS[1] ? this.femaleImg : this.maleImg;
  }

  checkPath(src: string): string {
    return src
      ? src
      : this.accesskey === GENDERS[1]
      ? this.femaleImg
      : this.maleImg;
  }
}
