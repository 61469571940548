import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'metutors-grid-placeholder',
  templateUrl: './grid-placeholder.component.html',
  styleUrls: ['./grid-placeholder.component.scss'],
})
export class GridPlaceholderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
