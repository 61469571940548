<metutors-modal [showModal]="showModal" (closeModal)="closeModal.emit()"
                [size]="'large'" [heading]="'LANGUAGE_CURRENCY'"
                [template]="template" [panelClass]="'filter-blur-10'">
  <ng-template #template>
    <div class="lang-currency">
      <h1>{{ 'LANGUAGE' | translate }}</h1>
      <div class="mb-4 row">
        <div class="col-md-4">
          <button class="menu-item lang-item w-100" [class.active]="selectedLanguage === 'ar'" (click)="languageSelect.emit('ar')">العربية</button>
        </div>
        <div class="col-md-4">
          <button class="menu-item lang-item w-100" [class.active]="selectedLanguage === 'en'" (click)="languageSelect.emit('en')">English</button>
        </div>
      </div>

      <h1>{{ 'CURRENCY' | translate }}</h1>
      <div class="currency-block row">
        <div class="col-md-4" *ngFor="let item of currencyRates">
          <button class="menu-item w-100" (click)="currencySelect.emit(item)" [ngClass]="{
              'active' : item.id === selectedCurrency?.id
            }">
            <strong>{{ item?.id }}</strong>
            <span>{{ item?.currency }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</metutors-modal>