import { createReducer, on } from '@ngrx/store';
import * as gptActions from '../actions/gpt.actions';
import * as generalActions from '../actions/general.actions';

export interface State {
  paddingTop: number;
  openLanguageCurrencyModal: boolean;

  // Success Modal
  showSuccessModal: boolean;
}

export const initialState: State = {
  paddingTop: 0,
  showSuccessModal: false,
  openLanguageCurrencyModal: false,
};

export const reducer = createReducer(
  initialState,

  on(generalActions.setWebsitePaddingTop, (state, { paddingTop }) => ({
    ...state,
    paddingTop,
  })),

  on(generalActions.openLanguageCurrencyModal, state => ({
    ...state,
    openLanguageCurrencyModal: true,
  })),

  on(generalActions.closeLanguageCurrencyModal, state => ({
    ...state,
    openLanguageCurrencyModal: false,
  })),

  on(
    generalActions.openSuccessModal,
    gptActions.unsubscribeGPTPackageSuccess,
    gptActions.verifyGPTRefillSubscriptionSuccess,
    gptActions.verifyGPTPackageSubscriptionSuccess,
    state => ({
      ...state,
      showSuccessModal: true,
    })
  ),

  on(generalActions.closeSuccessModal, state => ({
    ...state,
    showSuccessModal: false,
  }))
);

export const selectWebsitePaddingTop = (state: State): number =>
  state.paddingTop;

export const selectSuccessModal = (state: State): boolean =>
  state.showSuccessModal;

export const selectIsOpenLanguageCurrencyModal = (state: State): boolean =>
  state.openLanguageCurrencyModal;
