import { createAction, props } from '@ngrx/store';

// Gpt feedback
export const loadGptFeedbackOptions = createAction(
  '[Feedback] Load Gpt Feedback Options'
);

export const loadGptFeedbackOptionsSuccess = createAction(
  '[Feedback] Load Gpt Feedback Options Success',
  props<{ feedbackOptions: any }>()
);

export const loadGptFeedbackOptionsFailure = createAction(
  '[Feedback] Load Gpt Feedback Options Failure',
  props<{ error: any }>()
);
