<metutors-modal [showModal]="showModal" [size]="size"
                [template]="template" [showHeader]="false"
                [panelClass]="panelClass">
  <ng-template #template>
    <div class="success-modal {{ extraClasses }}">
      <img [src]="'assets/svg/'+ icon" draggable="false" class="d-block mx-auto my-4" alt="" width="70">
      <h1 class="text-center px-5 mt-2 heading" *ngIf="heading" [innerHTML]="heading | translate"></h1>
      <p class="text-center px-5 mt-2 mb-4 subheading" *ngIf="subHeading">{{ subHeading | translate }}</p>
      <h2 class="text-center px-5 hint mb-4" *ngIf="hint">{{ hint | translate }}</h2>
      <button mat-flat-button color="primary" class="py-1 w-100 rounded-btn" [routerLink]="routerLink" (click)="closeModal.emit()">
        {{ buttonLabel | translate }}
      </button>
    </div>
  </ng-template>
</metutors-modal>
