import { sortBy } from 'lodash';
import { IPlan } from '@metutors/core/models';
import { createReducer, on } from '@ngrx/store';
import * as gptActions from '../actions/gpt.actions';
import * as languageActions from '../actions/language-menu.actions';
import {
  GPTSubjects,
  GPTFriendlyAI,
  GPTSchoolStores,
  GPTUniversityStores,
} from '@metutors/config';

export interface State {
  subject: any;
  feedback: any;
  plans: IPlan[];
  questions: any;
  answer: string;
  threadId: string;
  paymentInfo: any;
  feedbacks: any[];
  subscriptions: any[];
  isAnswerGPT: boolean;
  annualDiscount: number;
  feedbacksCounts: number;
  isGiveFeedback: boolean;
  isLoadingPlans: boolean;
  isRefillPackage: boolean;
  remainingQueries: number;
  subscriptionsCounts: any;
  isUpgradePackage: boolean;
  isLoadingFeedbacks: boolean;
  isGettingFreeTrial: boolean;
  isSubscribePackage: boolean;
  isUnsubscribePackage: boolean;
  isLoadingFeedbackById: boolean;
  isLoadingSubscriptions: boolean;

  // History
  history: any[];
  isLoadingHistory: boolean;
  swipeHistoryRight: boolean;

  // Record Voice
  recordedVoice: string;
  isRecordVoice: boolean;
}

export const initialState: State = {
  plans: [],
  answer: '',
  history: [],
  threadId: '',
  questions: [],
  feedbacks: [],
  feedback: null,
  recordedVoice: '',
  subscriptions: [],
  paymentInfo: null,
  annualDiscount: 0,
  feedbacksCounts: 0,
  isAnswerGPT: false,
  remainingQueries: -1,
  isRecordVoice: false,
  isLoadingPlans: false,
  isGiveFeedback: false,
  subject: GPTFriendlyAI,
  isRefillPackage: false,
  isUpgradePackage: false,
  isLoadingHistory: false,
  subscriptionsCounts: {},
  swipeHistoryRight: false,
  isLoadingFeedbacks: false,
  isGettingFreeTrial: false,
  isSubscribePackage: false,
  isUnsubscribePackage: false,
  isLoadingFeedbackById: false,
  isLoadingSubscriptions: false,
};

export const reducer = createReducer(
  initialState,

  // Load Plans
  on(gptActions.loadPlans, state => ({
    ...state,
    isLoadingPlans: true,
  })),

  on(gptActions.loadPlansSuccess, (state, { plans, annualDiscount }) => ({
    ...state,
    annualDiscount,
    isLoadingPlans: false,
    plans:
      plans && plans.length
        ? plans.map(plan => ({
            ...plan,
            name:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? plan?.nameAr
                : plan?.nameEn,
          }))
        : [],
  })),

  on(gptActions.loadPlansEnded, gptActions.loadPlansFailure, state => ({
    ...state,
    isLoadingPlans: false,
  })),

  // Answer GPT
  on(gptActions.answerGPT, (state, { body }) => ({
    ...state,
    isAnswerGPT: true,
    questions:
      state.questions && state.questions.length
        ? [...state.questions, { ...body, isMine: true }]
        : [{ ...body, isMine: true }],
  })),

  on(gptActions.answerGPTSuccess, (state, { answer, threadId, history }) => {
    const output: any[] = [...state.history] || [];

    const existing = output.filter((v, i) => +v.id == +history.subjectId);

    if (existing.length) {
      const existingIndex = output.indexOf(existing[0]);

      output[existingIndex] = {
        ...output[existingIndex],
        questions: [...output[existingIndex].questions, { ...history }],
      };
    } else {
      output.push({
        ...[
          ...GPTSubjects,
          GPTFriendlyAI,
          ...GPTSchoolStores,
          ...GPTUniversityStores,
        ].find(hist => +hist.id === +history.subjectId),
        questions: [{ ...history }],
      });
    }

    return {
      ...state,
      answer,
      threadId,
      isAnswerGPT: false,
      history: sortBy(output, 'id'),
      questions:
        state.questions && state.questions.length
          ? [
              ...state.questions,
              {
                ...history,
                isMine: false,
                name: [
                  ...GPTSubjects,
                  GPTFriendlyAI,
                  ...GPTSchoolStores,
                  ...GPTUniversityStores,
                ].find(hist => +hist.id === +history.subjectId)?.name,
              },
            ]
          : [
              {
                ...history,
                isMine: false,
                name: [
                  ...GPTSubjects,
                  GPTFriendlyAI,
                  ...GPTSchoolStores,
                  ...GPTUniversityStores,
                ].find(hist => +hist.id === +history.subjectId)?.name,
              },
            ],
      remainingQueries:
        state.remainingQueries > 0 ? state.remainingQueries - 1 : -1,
    };
  }),

  on(gptActions.answerGPTFailure, state => ({
    ...state,
    isAnswerGPT: false,
  })),

  // Record Voice
  on(gptActions.recordVoice, state => ({
    ...state,
    isRecordVoice: true,
  })),

  on(gptActions.recordVoiceSuccess, (state, { prompt }) => ({
    ...state,
    isRecordVoice: false,
    recordedVoice: prompt,
  })),

  on(gptActions.recordVoiceFailure, state => ({
    ...state,
    isRecordVoice: false,
  })),

  on(gptActions.gptSubject, (state, { subject }) => ({
    ...state,
    subject,
    remainingQueries: -1,
    threadId: state.subject?.id === subject?.id ? state.threadId : '',
    questions: state.subject?.id === subject?.id ? state.questions : [],
  })),

  on(gptActions.loadRemainingQueriesSuccess, (state, { remainingQueries }) => ({
    ...state,
    remainingQueries,
  })),

  on(gptActions.loadUserGPTSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    remainingQueries: !subscription?.planId ? -1 : state.remainingQueries,
  })),

  on(gptActions.resetGPTAnswer, state => ({
    ...state,
    answer: '',
    isAnswerGPT: false,
  })),

  // Subscribe - Unsubscribe Package
  on(gptActions.subscribeGPTPackage, state => ({
    ...state,
    isSubscribePackage: true,
  })),

  on(gptActions.subscribeGPTPackageSuccess, (state, { paymentInfo }) => ({
    ...state,
    paymentInfo,
    isSubscribePackage: false,
  })),

  on(gptActions.subscribeGPTPackageFailure, state => ({
    ...state,
    isSubscribePackage: false,
  })),

  on(gptActions.unsubscribeGPTPackage, state => ({
    ...state,
    remainingQueries: -1,
    isUnsubscribePackage: true,
  })),

  on(gptActions.unsubscribeGPTPackageSuccess, state => ({
    ...state,
    isUnsubscribePackage: false,
  })),

  on(gptActions.unsubscribeGPTPackageFailure, state => ({
    ...state,
    isUnsubscribePackage: false,
  })),

  // Upgrade Package
  on(gptActions.upgradeGPTPackage, state => ({
    ...state,
    isUpgradePackage: true,
  })),

  on(gptActions.upgradeGPTPackageSuccess, (state, { paymentInfo }) => ({
    ...state,
    paymentInfo,
    isUpgradePackage: false,
  })),

  on(gptActions.upgradeGPTPackageFailure, state => ({
    ...state,
    isUpgradePackage: false,
  })),

  // Refill Package
  on(gptActions.refillGPTPackage, state => ({
    ...state,
    isRefillPackage: true,
  })),

  on(gptActions.refillGPTPackageSuccess, (state, { paymentInfo }) => ({
    ...state,
    paymentInfo,
    isRefillPackage: false,
  })),

  on(gptActions.refillGPTPackageFailure, state => ({
    ...state,
    isRefillPackage: false,
  })),

  // Load History
  on(gptActions.loadGPTHistory, state => ({
    ...state,
    isLoadingHistory: true,
  })),

  on(gptActions.loadGPTHistorySuccess, (state, { list }) => ({
    ...state,
    history: list,
    isLoadingHistory: false,
  })),

  on(
    gptActions.loadGPTHistoryEnded,
    gptActions.loadGPTHistoryFailure,
    state => ({
      ...state,
      isLoadingHistory: false,
    })
  ),

  // Get Free Trial
  on(gptActions.getGPTFreeTrial, state => ({
    ...state,
    isGettingFreeTrial: true,
  })),

  on(
    gptActions.getGPTFreeTrialEnded,
    gptActions.getGPTFreeTrialSuccess,
    gptActions.getGPTFreeTrialFailure,
    state => ({
      ...state,
      isGettingFreeTrial: false,
    })
  ),

  // Load GPT Subscriptions
  on(gptActions.loadSubscriptions, state => ({
    ...state,
    isLoadingSubscriptions: true,
  })),

  on(
    gptActions.loadSubscriptionsSuccess,
    (state, { subscriptions, subscriptionsCounts }) => ({
      ...state,
      subscriptions,
      subscriptionsCounts,
      isLoadingSubscriptions: false,
    })
  ),

  on(gptActions.loadSubscriptionsFailure, (state, { error }) => ({
    ...state,
    isLoadingSubscriptions: false,
    loadingSubscriptionsFailure: error,
  })),

  // Give Feedback
  on(gptActions.giveGPTFeedback, state => ({
    ...state,
    isGiveFeedback: true,
  })),

  on(
    gptActions.giveGPTFeedbackSuccess,
    gptActions.giveGPTFeedbackFailure,
    state => ({
      ...state,
      isGiveFeedback: false,
    })
  ),

  // Loading Feedbacks
  on(gptActions.loadGPTFeedback, state => ({
    ...state,
    isLoadingFeedbacks: true,
  })),

  on(
    gptActions.loadGPTFeedbackSuccess,
    (state, { feedbacks, feedbacksCounts }) => ({
      ...state,
      feedbacks,
      feedbacksCounts,
      isLoadingFeedbacks: false,
    })
  ),

  on(gptActions.loadGPTFeedbackFailure, state => ({
    ...state,
    isLoadingFeedbacks: false,
  })),

  // Loading Feedback By Id
  on(gptActions.loadGPTFeedbackById, state => ({
    ...state,
    isLoadingFeedbackById: true,
  })),

  on(gptActions.loadGPTFeedbackByIdSuccess, (state, { feedback }) => ({
    ...state,
    feedback,
    isLoadingFeedbackById: false,
  })),

  on(gptActions.loadGPTFeedbackByIdFailure, state => ({
    ...state,
    isLoadingFeedbackById: false,
  })),

  // Swipe GPT History Right
  on(gptActions.swipeGPTHistoryRight, state => ({
    ...state,
    swipeHistoryRight: !state.swipeHistoryRight,
  })),

  on(languageActions.changeLanguage, state => ({
    ...state,
    plans:
      state.plans && state.plans.length
        ? state.plans.map(plan => ({
            ...plan,
            name:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? plan?.nameAr
                : plan?.nameEn,
          }))
        : [],
  }))
);

export const selectIsAnswerGPT = (state: State): boolean => state.isAnswerGPT;

export const selectGPTAnswer = (state: State): string => state.answer;

export const selectRecordedVoice = (state: State): string =>
  state.recordedVoice;

export const selectIsRecordVoice = (state: State): boolean =>
  state.isRecordVoice;

export const selectGPTThreadId = (state: State): string => state.threadId;

export const selectGPTQuestions = (state: State): any[] => state.questions;

export const selectGPTSubject = (state: State): any => state.subject;

export const selectGPTRemainingQueries = (state: State): any =>
  state.remainingQueries;

export const selectIsSubscribeGPTPackage = (state: State): boolean =>
  state.isSubscribePackage;

export const selectIsUnsubscribeGPTPackage = (state: State): boolean =>
  state.isUnsubscribePackage;

export const selectIsUpgradeGPTPackage = (state: State): boolean =>
  state.isUpgradePackage;

export const selectIsRefillGPTPackage = (state: State): boolean =>
  state.isRefillPackage;

export const selectGPTPaymentInfo = (state: State): any => state.paymentInfo;

export const selectGPTHistory = (state: State): any[] => state.history;

export const selectIsLoadingGPTHistory = (state: State): boolean =>
  state.isLoadingHistory;

export const selectPlans = (state: State): IPlan[] => state.plans;

export const selectIsLoadingPlans = (state: State): boolean =>
  state.isLoadingPlans;

export const selectIsGettingFreeTrial = (state: State): boolean =>
  state.isGettingFreeTrial;

export const selectAnnualDiscount = (state: State): number =>
  state.annualDiscount;

export const selectSubscriptions = (state: State): any[] | null =>
  state.subscriptions;

export const selectSubscriptionsCounts = (state: State): any =>
  state.subscriptionsCounts;

export const selectIsLoadingSubscriptions = (state: State): boolean =>
  state.isLoadingSubscriptions;

export const selectIsGiveGPTFeedback = (state: State): boolean =>
  state.isGiveFeedback;

export const selectIsLoadingGPTFeedbacks = (state: State): boolean =>
  state.isLoadingFeedbacks;

export const selectGPTFeedbacks = (state: State): any => state.feedbacks;

export const selectGPTFeedbacksCounts = (state: State): number =>
  state.feedbacksCounts;

export const selectIsLoadingGPTFeedbackById = (state: State): boolean =>
  state.isLoadingFeedbackById;

export const selectGPTFeedbackById = (state: State): any => state.feedback;

export const selectGPTSwipeHistoryRight = (state: State): boolean =>
  state.swipeHistoryRight;
