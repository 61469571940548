<vg-player class="rounded" [ngStyle]="{'height': height}" *ngIf="videoSrc">
  <vg-overlay-play></vg-overlay-play>
  <vg-buffering></vg-buffering>

  <vg-scrub-bar>
    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
  </vg-scrub-bar>

  <vg-controls>
    <vg-play-pause></vg-play-pause>
    <vg-playback-button></vg-playback-button>

    <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

    <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

    <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

    <!-- <vg-track-selector></vg-track-selector> -->
    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

    <vg-fullscreen></vg-fullscreen>
  </vg-controls>

  <video [vgMedia]="$any(media)" [src]="videoSrc" [poster]="poster" #media id="singleVideo" preload="auto" crossorigin>
    <source [src]="videoSrc" type="video/mp4">

    <!-- <track kind="subtitles" label="English" src="http://static.videogular.com/assets/subs/pale-blue-dot.vtt"
      srclang="en" default>
    <track kind="subtitles" label="Español" src="http://static.videogular.com/assets/subs/pale-blue-dot-es.vtt"
      srclang="es"> -->
  </video>
</vg-player>
