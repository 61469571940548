import { createReducer, on } from '@ngrx/store';
import * as lookupsActions from '../actions/lookups.actions';
import * as languageActions from '../actions/language-menu.actions';

import { ICountry, IProgram, IRole } from '@models';

export interface State {
  // User types
  userTypes: IRole[];
  isLoadingUserTypes?: boolean;
  loadingUserTypesFailure?: string;

  // Program countries
  programCountries: ICountry[] | null;
  isLoadingProgramCountries: boolean;
  loadingProgramCountriesFailure?: string;

  // Programs
  programs: IProgram[] | null;
  isLoadingPrograms: boolean;
  loadingProgramsFailure?: string;
}

export const initialState: State = {
  userTypes: [],
  programs: null,
  programCountries: null,
  isLoadingPrograms: false,
  isLoadingUserTypes: false,
  isLoadingProgramCountries: false,
};

export const reducer = createReducer(
  initialState,

  on(lookupsActions.loadUserTypes, state => ({
    ...state,
    isLoadingUserTypes: true,
  })),

  on(lookupsActions.loadUserTypesSuccess, (state, { userTypes }) => ({
    ...state,
    userTypes,
    isLoadingUserTypes: false,
  })),

  on(lookupsActions.loadUserTypesFailure, (state, { error }) => ({
    ...state,
    isLoadingUserTypes: false,
    loadingUserTypesFailure: error,
  })),

  on(lookupsActions.loadUserTypesEnded, state => ({
    ...state,
    isLoadingUserTypes: false,
  })),

  on(lookupsActions.loadProgramCountries, state => ({
    ...state,
    isLoadingProgramCountries: true,
  })),

  on(lookupsActions.loadProgramCountriesSuccess, (state, { countries }) => ({
    ...state,
    programCountries:
      countries && countries.length
        ? countries.map(country => ({
            ...country,
            name:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? country?.nameAr
                : country?.nameEn,
            description:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? country?.descriptionAr
                : country?.descriptionEn,
          }))
        : [],
    isLoadingProgramCountries: false,
  })),

  on(lookupsActions.loadProgramCountriesFailure, (state, { error }) => ({
    ...state,
    isLoadingProgramCountries: false,
    loadingProgramCountriesFailure: error,
  })),

  on(lookupsActions.loadProgramCountriesEnded, state => ({
    ...state,
    isLoadingProgramCountries: false,
  })),

  on(lookupsActions.loadPrograms, state => ({
    ...state,
    isLoadingPrograms: true,
  })),

  on(lookupsActions.loadProgramsSuccess, (state, { programs }) => ({
    ...state,
    programs:
      programs && programs.length
        ? programs.map(program => ({
            ...program,
            name:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? program?.nameAr
                : program?.nameEn,
            title:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? program?.titleAr
                : program?.titleEn,
            description:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? program?.descriptionAr
                : program?.descriptionEn,
          }))
        : [],
    isLoadingPrograms: false,
  })),

  on(lookupsActions.loadProgramsFailure, (state, { error }) => ({
    ...state,
    isLoadingPrograms: false,
    loadingProgramsFailure: error,
  })),

  on(lookupsActions.loadProgramsEnded, state => ({
    ...state,
    isLoadingPrograms: false,
  })),

  on(languageActions.changeLanguage, state => ({
    ...state,
    programs:
      state.programs && state.programs.length
        ? state.programs.map(program => ({
            ...program,
            name:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? program?.nameAr
                : program?.nameEn,
            title:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? program?.titleAr
                : program?.titleEn,
            description:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? program?.descriptionAr
                : program?.descriptionEn,
          }))
        : [],
    programCountries:
      state.programCountries && state.programCountries.length
        ? state.programCountries.map(country => ({
            ...country,
            name:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? country?.nameAr
                : country?.nameEn,
            description:
              localStorage.getItem('DEFAULT_LANGUAGE') === 'ar'
                ? country?.descriptionAr
                : country?.descriptionEn,
          }))
        : [],
  }))
);

export const selectUserTypes = (state: State): IRole[] => state.userTypes;

export const selectIsLoadingUserTypes = (state: State): boolean | undefined =>
  state.isLoadingUserTypes;

export const selectProgramCountries = (state: State): ICountry[] | null =>
  state.programCountries;

export const selectIsLoadingProgramCountries = (state: State): boolean =>
  state.isLoadingProgramCountries;

export const selectPrograms = (state: State): IProgram[] | null =>
  state.programs;

export const selectIsLoadingPrograms = (state: State): boolean =>
  state.isLoadingPrograms;

export const selectFilteredProgramCountries = (
  state: State,
  props?: any
): ICountry[] | null => {
  let countries: ICountry[] = state.programCountries!;

  if (state.programCountries && state.programCountries.length && props) {
    if (props?.search) {
      countries = countries?.filter(country =>
        country?.name.toLowerCase().includes(props.search.toLowerCase())
      );
    }
  }

  return countries;
};
