<ng-progress #progressBar></ng-progress>

<div class="d-flex flex-column full-height" *ngIf="!maintenanceMode">
  <metutors-navbar *ngIf="layout === undefined || layout.showHeader || layout.showHeader === undefined"
                   [url]="url"
                   [countries]="(countries$ | async)!"
                   [subscription]="(subscription$ | async)!"
                   [loadingCountries]="(loadingCountries$ | async)!"
                   (calculateHeight)="onCalculateHeight($event)">
  </metutors-navbar>

  <div class="flex-grow-1"
       [ngStyle]="{'padding-top.px': (layout === undefined || layout.showHeader || layout.showHeader === undefined) ? (paddingTop$ | async)! : 0}">
    <router-outlet></router-outlet>
  </div>

  <metutors-footer *ngIf="layout === undefined || layout.showFooter || layout.showFooter === undefined">
  </metutors-footer>
</div>

<alert-notifications></alert-notifications>

<metutors-chatbot [user]="(user$ | async)!"
                  *ngIf="!(url?.includes('/full'))">
</metutors-chatbot>

<metutors-signin-modal *ngIf="(openSignInModal$ | async)!"
                       [showModal]="(openSignInModal$ | async)!"
                       [subHeading]="(signInSubHeading$ | async)!"
                       [returnUrl]="(returnUrl$ | async)!"
                       (closeModal)="closeSignInModal()">
</metutors-signin-modal>

<metutors-under-maintenance *ngIf="maintenanceMode"></metutors-under-maintenance>
