import { GPTPackagesIcons } from './enums';

export const GENDERS = ['Male', 'Female'];

export const GPTSubjects = [
  {
    id: 1,
    shortName: 'ENGLISH',
    name: 'ENGLISH_AI',
    nameEn: 'English AI',
    nameAr: '⁠الانجليزية',
    desc: 'ENGLISH_AI_DESC',
    img: 'assets/gpt/english.svg',
    isAnimated: false,
    openStores: ['school', 'essential'],
  },
  {
    id: 2,
    shortName: 'ESSAY',
    name: 'ESSAY_BOOK_REPORT_AI',
    nameEn: 'Essay/Book Report AI',
    nameAr: '⁠المقالات و تقارير الكتب',
    desc: 'ESSAY_BOOK_REPORT_AI_DESC',
    img: 'assets/gpt/essay.svg',
    isAnimated: false,
    openStores: ['school', 'essential'],
  },
  {
    id: 3,
    shortName: 'HISTORY',
    name: 'HISTORY_SOCIAL_STUDIES_AI',
    nameEn: 'History / Social Studies AI',
    nameAr: '⁠التاريخ / الدراسات الاجتماعية',
    desc: 'HISTORY_SOCIAL_STUDIES_AI_DESC',
    img: 'assets/gpt/history.svg',
    isAnimated: false,
    openStores: ['school', 'essential'],
  },
  {
    id: 4,
    shortName: 'SCIENCE',
    name: 'SCIENCE_AI',
    nameEn: 'Science AI',
    nameAr: '⁠العلوم',
    desc: 'SCIENCE_AI_DESC',
    img: 'assets/gpt/science.svg',
    isAnimated: false,
    openStores: ['school', 'essential'],
  },
  {
    id: 5,
    shortName: 'MATH',
    name: 'MATH_AI_BETA',
    nameEn: 'Math AI (beta)',
    nameAr: 'الذكاء الاصطناعي للرياضيات (تجريبي)',
    desc: 'MATH_AI_BETA_DESC',
    img: 'assets/gpt/math.svg',
    isAnimated: false,
    openStores: ['school', 'essential'],
  },
];

export const GPTFriendlyAI = {
  id: 6,
  shortName: 'FRIENDLY_AI',
  name: 'FRIENDLY_AI',
  nameEn: 'Friendly AI',
  nameAr: 'الذكاء الاصطناعي الصديق',
  desc: 'FRIENDLY_AI_DESC',
  img: 'assets/gpt/friendly.svg',
  isAnimated: false,
  openStores: ['school'],
};

export const GPTSchoolStores = [
  {
    id: 21,
    parent: 'SCIENCE_HUB',
    img: 'assets/gpt/chemistry.svg',
    name: 'CHEMISTRY',
    nameEn: 'Chemistry',
    nameAr: 'Chemistry',
    description: 'FRIENDLY_CHEMISTRY_GUIDE_STUDENTS',
    openStores: ['school'],
  },
  {
    id: 22,
    parent: 'SCIENCE_HUB',
    img: 'assets/gpt/botany.svg',
    name: 'BOTANY',
    nameEn: 'Botany',
    nameAr: 'Botany',
    description: 'BOTANY_LEARNING_ASSISTANT_MEMORY',
    openStores: ['school'],
  },
  {
    id: 23,
    parent: 'SCIENCE_HUB',
    img: 'assets/gpt/zoology.svg',
    name: 'ZOOLOGY',
    nameEn: 'Zoology',
    nameAr: 'Zoology',
    description: 'FRIENDLY_GUIDE_ANIMAL_BIOLOGY',
    openStores: ['school'],
  },
  {
    id: 9,
    parent: 'SCIENCE_HUB',
    img: 'assets/gpt/science-lab.svg',
    name: 'SCIENCELAB_ASSISTANT',
    nameEn: 'ScienceLab Assistant',
    nameAr: 'ScienceLab Assistant',
    description: 'LAB_EXPERIMENTS_DATA_ANALYSIS',
    openStores: ['school'],
  },
  {
    id: 11,
    parent: 'SCIENCE_HUB',
    img: 'assets/gpt/math-master.svg',
    name: 'MATHMASTER_PRO',
    nameEn: 'MathMaster Pro',
    nameAr: 'MathMaster Pro',
    description: 'PATIENT_KNOWLEDGEABLE_MATH_TEACHER',
    openStores: ['school'],
  },
  {
    id: 14,
    parent: 'SCIENCE_HUB',
    img: 'assets/gpt/physics.svg',
    name: 'PHYSICS_PRO',
    nameEn: 'Physics Pro',
    nameAr: 'Physics Pro',
    description: 'CLEAR_ENGAGING_PHYSICS_EXPERT',
    openStores: ['school'],
  },
  {
    id: 15,
    parent: 'EXAM_PREP_PAL',
    img: 'assets/gpt/sat.svg',
    name: 'SAT_PREP_PAL',
    nameEn: 'SAT Prep Pal',
    nameAr: 'SAT Prep Pal',
    description: 'DEDICATED_SAT_EXAM_TUTOR',
    openStores: ['school'],
  },
  {
    id: 16,
    parent: 'EXAM_PREP_PAL',
    img: 'assets/gpt/act.svg',
    name: 'ACT_ACE',
    nameEn: 'ACT Ace',
    nameAr: 'ACT Ace',
    description: 'EXPERT_VARIOUS_ACADEMIC_SUBJECTS',
    openStores: ['school'],
  },
  {
    id: 17,
    parent: 'EXAM_PREP_PAL',
    img: 'assets/gpt/ielts.svg',
    name: 'IELTS',
    nameEn: 'IELTS',
    nameAr: 'IELTS',
    description: 'FOCUSED_IELTS_EXAM_ASSISTANT',
    openStores: ['school'],
  },
  {
    id: 18,
    parent: 'EXAM_PREP_PAL',
    img: 'assets/gpt/gre.svg',
    name: 'GRE_PREP_PAL',
    nameEn: 'GRE Prep Pal',
    nameAr: 'GRE Prep Pal',
    description: 'EXAM_GUIDE_STRATEGY_ADVISOR',
    openStores: ['school'],
  },
  {
    id: 19,
    parent: 'EXAM_PREP_PAL',
    img: 'assets/gpt/gmat.svg',
    name: 'GMAT_PREP_PAL',
    nameEn: 'GMAT Prep Pal',
    nameAr: 'GMAT Prep Pal',
    description: 'GMAT_PREPARATION_INSIGHTS',
    openStores: ['school'],
  },
  {
    id: 20,
    parent: 'EXAM_PREP_PAL',
    img: 'assets/gpt/toefl.svg',
    name: 'TOEFL_PREP_PAL',
    nameEn: 'TOEFl Prep Pal',
    nameAr: 'TOEFl Prep Pal',
    description: 'ENGLISH_MASTERY_GUIDE_ACADEMIC_SUCCESS',
    openStores: ['school'],
  },
  {
    id: 7,
    parent: 'HUMANITIES',
    img: 'assets/gpt/econ.svg',
    name: 'ECON_EXPERT',
    nameEn: 'EconExpert',
    nameAr: 'EconExpert',
    description: 'CLEAR_EXPLANATION_ECONOMICS',
    openStores: ['school'],
  },
  {
    id: 8,
    parent: 'HUMANITIES',
    img: 'assets/gpt/geography.svg',
    name: 'GEOGRAPHY_EXPLORER',
    nameEn: 'GeographyExplorer',
    nameAr: 'GeographyExplorer',
    description: 'GUIDE_WORLD_GEOGRAPHY',
    openStores: ['school'],
  },
  {
    id: 10,
    parent: 'HUMANITIES',
    img: 'assets/gpt/language-lab.svg',
    name: 'LANGUAGELAB_PRO',
    nameEn: 'LanguageLab Pro',
    nameAr: 'LanguageLab Pro',
    description: 'LANGUAGE_SKILL_ENHANCER_PRONUNCIATION',
    openStores: ['school'],
  },
  {
    id: 24,
    parent: 'TIME_SAVERS',
    img: 'assets/gpt/ask-pdf.svg',
    name: 'ASK_YOUR_PDF',
    nameEn: 'AskYourPDF',
    nameAr: 'AskYourPDF',
    description: 'EXTRACTS_INSIGHTS_PDF_DOCUMENTS',
    openStores: ['school'],
  },
  // {
  //   id: 25,
  //   parent: 'TIME_SAVERS',
  //   img: 'assets/gpt/video-summarize.svg',
  //   name: 'VIDEO_SUMMARIZER',
  //   nameEn: 'Video Summarizer',
  //   nameAr: 'Video Summarizer',
  //   description: 'SUMMARIZES_VIDEOS_STUDY_RESEARCH',
  //   openStores: ['school'],
  // },
  {
    id: 12,
    parent: 'MISC',
    img: 'assets/gpt/career.svg',
    name: 'CAREER_360',
    nameEn: 'Career 360',
    nameAr: 'Career 360',
    description: 'CAREER_EXPLORATION_SKILL_DEVELOPMENT',
    openStores: ['school'],
  },
  {
    id: 13,
    parent: 'MISC',
    img: 'assets/gpt/scholar.svg',
    name: 'SCHOLAR_AI',
    nameEn: 'ScholarAI',
    nameAr: 'ScholarAI',
    description: 'SIMPLIFYING_COMPLEX_RESEARCH',
    openStores: ['school'],
  },
];

export const GPTUniversityStores = [
  {
    id: 26,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine1.svg',
    name: 'ANATOMEASE_TUTOR',
    nameEn: 'AnatomEase Tutor',
    nameAr: 'AnatomEase Tutor',
    description: 'ANATOMEASE_TUTOR_DESC',
    openStores: ['university'],
  },
  {
    id: 27,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine2.svg',
    name: 'PHYSIOPHRIEND',
    nameEn: 'PhysioPhriend',
    nameAr: 'PhysioPhriend',
    description: 'PHYSIOPHRIEND_DESC',
    openStores: ['university'],
  },
  {
    id: 28,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine3.svg',
    name: 'BIOCHEMBUDDY',
    nameEn: 'BiochemBuddy',
    nameAr: 'BiochemBuddy',
    description: 'BIOCHEMBUDDY_DESC',
    openStores: ['university'],
  },
  {
    id: 29,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine4.svg',
    name: 'PHARMAFACILITATOR',
    nameEn: 'PharmaFacilitator',
    nameAr: 'PharmaFacilitator',
    description: 'PHARMAFACILITATOR_DESC',
    openStores: ['university'],
  },
  {
    id: 30,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine5.svg',
    name: 'PATHOPIONEER',
    nameEn: 'PathoPioneer',
    nameAr: 'PathoPioneer',
    description: 'PATHOPIONEER_DESC',
    openStores: ['university'],
  },
  {
    id: 31,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine6.svg',
    name: 'MICROBEMASTER',
    nameEn: 'MicrobeMaster',
    nameAr: 'MicrobeMaster',
    description: 'MICROBEMASTER_DESC',
    openStores: ['university'],
  },
  {
    id: 32,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine7.svg',
    name: 'FORENSICFRONTIERS_GUIDE',
    nameEn: 'ForensicFrontiers Guide',
    nameAr: 'ForensicFrontiers Guide',
    description: 'FORENSICFRONTIERS_GUIDE_DESC',
    openStores: ['university'],
  },
  {
    id: 33,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine8.svg',
    name: 'COMMUNICARE_MENTOR',
    nameEn: 'CommuniCare Mentor',
    nameAr: 'CommuniCare Mentor',
    description: 'COMMUNICARE_MENTOR_DESC',
    openStores: ['university'],
  },
  {
    id: 34,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine9.svg',
    name: 'PEDIAPAL',
    nameEn: 'PediaPal',
    nameAr: 'PediaPal',
    description: 'PEDIAPAL_DESC',
    openStores: ['university'],
  },
  {
    id: 35,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine10.svg',
    name: 'OBGYNORACLE',
    nameEn: 'ObGynOracle',
    nameAr: 'ObGynOracle',
    description: 'OBGYNORACLE_DESC',
    openStores: ['university'],
  },
  {
    id: 36,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine11.svg',
    name: 'SURGICALSTRATEGIST',
    nameEn: 'SurgicalStrategist',
    nameAr: 'SurgicalStrategist',
    description: 'SURGICALSTRATEGIST_DESC',
    openStores: ['university'],
  },
  {
    id: 37,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine12.svg',
    name: 'MEDIMENTOR',
    nameEn: 'MediMentor',
    nameAr: 'MediMentor',
    description: 'MEDIMENTOR_DESC',
    openStores: ['university'],
  },
  {
    id: 38,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine13.svg',
    name: 'DERMOCOMPANION',
    nameEn: 'DermoCompanion',
    nameAr: 'DermoCompanion',
    description: 'DERMOCOMPANION_DESC',
    openStores: ['university'],
  },
  {
    id: 39,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine14.svg',
    name: 'PSYCHEPILOT',
    nameEn: 'PsychePilot',
    nameAr: 'PsychePilot',
    description: 'PSYCHEPILOT_DESC',
    openStores: ['university'],
  },
  {
    id: 40,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine15.svg',
    name: 'RADIOLOGYREVEALER',
    nameEn: 'RadiologyRevealer',
    nameAr: 'RadiologyRevealer',
    description: 'RADIOLOGYREVEALER_DESC',
    openStores: ['university'],
  },
  {
    id: 41,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine16.svg',
    name: 'OPHTHALMOGUIDE',
    nameEn: 'OphthalmoGuide',
    nameAr: 'OphthalmoGuide',
    description: 'OPHTHALMOGUIDE_DESC',
    openStores: ['university'],
  },
  {
    id: 42,
    parent: 'MEDICINE',
    img: 'assets/gpt/medicine17.svg',
    name: 'ENTENLIGHTENER',
    nameEn: 'ENTEnlightener',
    nameAr: 'ENTEnlightener',
    description: 'ENTENLIGHTENER_DESC',
    openStores: ['university'],
  },
  {
    id: 43,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering1.svg',
    name: 'MECHANOMENTOR',
    nameEn: 'MechanoMentor',
    nameAr: 'MechanoMentor',
    description: 'MECHANOMENTOR_DESC',
    openStores: ['university'],
  },
  {
    id: 44,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering2.svg',
    name: 'ELECTROENLIGHTENER',
    nameEn: 'ElectroEnlightener',
    nameAr: 'ElectroEnlightener',
    description: 'ELECTROENLIGHTENER_DESC',
    openStores: ['university'],
  },
  {
    id: 45,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering3.svg',
    name: 'CIVILITYSCULPTOR',
    nameEn: 'CivilitySculptor',
    nameAr: 'CivilitySculptor',
    description: 'CIVILITYSCULPTOR_DESC',
    openStores: ['university'],
  },
  {
    id: 46,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering4.svg',
    name: 'CHEMCRAFTER',
    nameEn: 'ChemCrafter',
    nameAr: 'ChemCrafter',
    description: 'CHEMCRAFTER_DESC',
    openStores: ['university'],
  },
  {
    id: 47,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering5.svg',
    name: 'AEROARCHITECT',
    nameEn: 'AeroArchitect',
    nameAr: 'AeroArchitect',
    description: 'AEROARCHITECT_DESC',
    openStores: ['university'],
  },
  {
    id: 48,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering6.svg',
    name: 'COMPUCRAFT_ENGINEER',
    nameEn: 'Compucraft Engineer',
    nameAr: 'Compucraft Engineer',
    description: 'COMPUCRAFT_ENGINEER_DESC',
    openStores: ['university'],
  },
  {
    id: 49,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering7.svg',
    name: 'BIOBIONIC_GUIDE',
    nameEn: 'BioBionic Guide',
    nameAr: 'BioBionic Guide',
    description: 'BIOBIONIC_GUIDE_DESC',
    openStores: ['university'],
  },
  {
    id: 50,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering8.svg',
    name: 'INDUSTRIOSTREAMLINER',
    nameEn: 'IndustrioStreamliner',
    nameAr: 'IndustrioStreamliner',
    description: 'INDUSTRIOSTREAMLINER_DESC',
    openStores: ['university'],
  },
  {
    id: 51,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering9.svg',
    name: 'ECOENGINE_INNOVATOR',
    nameEn: 'EcoEngine Innovator',
    nameAr: 'EcoEngine Innovator',
    description: 'ECOENGINE_INNOVATOR_DESC',
    openStores: ['university'],
  },
  {
    id: 52,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering10.svg',
    name: 'MATERIALMAESTRO',
    nameEn: 'MaterialMaestro',
    nameAr: 'MaterialMaestro',
    description: 'MATERIALMAESTRO_DESC',
    openStores: ['university'],
  },
  {
    id: 53,
    parent: 'ENGINEERING',
    img: 'assets/gpt/engineering11.svg',
    name: 'PETROPRODIGY',
    nameEn: 'PetroProdigy',
    nameAr: 'PetroProdigy',
    description: 'PETROPRODIGY_DESC',
    openStores: ['university'],
  },
  {
    id: 54,
    parent: 'MATHS',
    img: 'assets/gpt/math1.svg',
    name: 'ALGEBRA_AVATAR',
    nameEn: 'Algebra Avatar',
    nameAr: 'Algebra Avatar',
    description: 'ALGEBRA_AVATAR_DESC',
    openStores: ['university'],
  },
  {
    id: 55,
    parent: 'MATHS',
    img: 'assets/gpt/math2.svg',
    name: 'CALCULUS_COMPANION',
    nameEn: 'Calculus Companion',
    nameAr: 'Calculus Companion',
    description: 'CALCULUS_COMPANION_DESC',
    openStores: ['university'],
  },
  {
    id: 56,
    parent: 'MATHS',
    img: 'assets/gpt/math3.svg',
    name: 'GEOMETRY_GENIUS',
    nameEn: 'Geometry Genius',
    nameAr: 'Geometry Genius',
    description: 'GEOMETRY_GENIUS_DESC',
    openStores: ['university'],
  },
  {
    id: 57,
    parent: 'MATHS',
    img: 'assets/gpt/math4.svg',
    name: 'STATISTICS_SAGE',
    nameEn: 'Statistics Sage',
    nameAr: 'Statistics Sage',
    description: 'STATISTICS_SAGE_DESC',
    openStores: ['university'],
  },
  {
    id: 58,
    parent: 'MATHS',
    img: 'assets/gpt/math5.svg',
    name: 'APPLIED_MATH_MAGICIAN',
    nameEn: 'Applied Math Magician',
    nameAr: 'Applied Math Magician',
    description: 'APPLIED_MATH_MAGICIAN_DESC',
    openStores: ['university'],
  },
  {
    id: 59,
    parent: 'PHYSICS',
    img: 'assets/gpt/physics1.svg',
    name: 'MECHANICS_MAESTRO',
    nameEn: 'Mechanics Maestro',
    nameAr: 'Mechanics Maestro',
    description: 'MECHANICS_MAESTRO_DESC',
    openStores: ['university'],
  },
  {
    id: 60,
    parent: 'PHYSICS',
    img: 'assets/gpt/physics2.svg',
    name: 'ELECTROMAGNET_ENCHANTER',
    nameEn: 'Electromagnet Enchanter',
    nameAr: 'Electromagnet Enchanter',
    description: 'ELECTROMAGNET_ENCHANTER_DESC',
    openStores: ['university'],
  },
  {
    id: 61,
    parent: 'PHYSICS',
    img: 'assets/gpt/physics3.svg',
    name: 'QUANTUM_QUELL',
    nameEn: 'Quantum Quell',
    nameAr: 'Quantum Quell',
    description: 'QUANTUM_QUELL_DESC',
    openStores: ['university'],
  },
  {
    id: 62,
    parent: 'PHYSICS',
    img: 'assets/gpt/physics4.svg',
    name: 'THERMODYNAMICS_DETECTIVE',
    nameEn: 'ThermoDynamics Detective',
    nameAr: 'ThermoDynamics Detective',
    description: 'THERMODYNAMICS_DETECTIVE_DESC',
    openStores: ['university'],
  },
  {
    id: 63,
    parent: 'PHYSICS',
    img: 'assets/gpt/physics5.svg',
    name: 'ASTROAIDE',
    nameEn: 'AstroAide',
    nameAr: 'AstroAide',
    description: 'ASTROAIDE_DESC',
    openStores: ['university'],
  },
  {
    id: 64,
    parent: 'CHEMISTRY_',
    img: 'assets/gpt/chemistry1.svg',
    name: 'ORGANIC_ORACLE',
    nameEn: 'Organic Oracle',
    nameAr: 'Organic Oracle',
    description: 'ORGANIC_ORACLE_DESC',
    openStores: ['university'],
  },
  {
    id: 65,
    parent: 'CHEMISTRY_',
    img: 'assets/gpt/chemistry2.svg',
    name: 'INORGANIC_INTELLECT',
    nameEn: 'Inorganic Intellect',
    nameAr: 'Inorganic Intellect',
    description: 'INORGANIC_INTELLECT_DESC',
    openStores: ['university'],
  },
  {
    id: 66,
    parent: 'CHEMISTRY_',
    img: 'assets/gpt/chemistry3.svg',
    name: 'PHYSICAL_CHEMISTRY_PIONEER',
    nameEn: 'Physical Chemistry Pioneer',
    nameAr: 'Physical Chemistry Pioneer',
    description: 'PHYSICAL_CHEMISTRY_PIONEER_DESC',
    openStores: ['university'],
  },
  {
    id: 67,
    parent: 'CHEMISTRY_',
    img: 'assets/gpt/chemistry4.svg',
    name: 'ANALYTICAL_ALCHEMIST',
    nameEn: 'Analytical Alchemist',
    nameAr: 'Analytical Alchemist',
    description: 'ANALYTICAL_ALCHEMIST_DESC',
    openStores: ['university'],
  },
  {
    id: 68,
    parent: 'CHEMISTRY_',
    img: 'assets/gpt/chemistry5.svg',
    name: 'BIOCHEM_BEACON',
    nameEn: 'Biochem Beacon',
    nameAr: 'Biochem Beacon',
    description: 'BIOCHEM_BEACON_DESC',
    openStores: ['university'],
  },
  {
    id: 69,
    parent: 'BIOLOGY',
    img: 'assets/gpt/biology1.svg',
    name: 'CELL_BIOLOGY_BEACON',
    nameEn: 'Cell Biology Beacon',
    nameAr: 'Cell Biology Beacon',
    description: 'CELL_BIOLOGY_BEACON_DESC',
    openStores: ['university'],
  },
  {
    id: 70,
    parent: 'BIOLOGY',
    img: 'assets/gpt/biology2.svg',
    name: 'GENETICS_GUIDE',
    nameEn: 'Genetics Guide',
    nameAr: 'Genetics Guide',
    description: 'GENETICS_GUIDE_DESC',
    openStores: ['university'],
  },
  {
    id: 71,
    parent: 'BIOLOGY',
    img: 'assets/gpt/biology3.svg',
    name: 'EVOENGINE',
    nameEn: 'EvoEngine',
    nameAr: 'EvoEngine',
    description: 'EVOENGINE_DESC',
    openStores: ['university'],
  },
  {
    id: 72,
    parent: 'BIOLOGY',
    img: 'assets/gpt/biology4.svg',
    name: 'ECOEXPLORER',
    nameEn: 'EcoExplorer',
    nameAr: 'EcoExplorer',
    description: 'ECOEXPLORER_DESC',
    openStores: ['university'],
  },
  {
    id: 73,
    parent: 'BIOLOGY',
    img: 'assets/gpt/biology5.svg',
    name: 'MOLECULAR_MUSE',
    nameEn: 'Molecular Muse',
    nameAr: 'Molecular Muse',
    description: 'MOLECULAR_MUSE_DESC',
    openStores: ['university'],
  },
  {
    id: 74,
    parent: 'LAW',
    img: 'assets/gpt/law1.svg',
    name: 'CONSTITUTIONAL_CODEX',
    nameEn: 'Constitutional Codex',
    nameAr: 'Constitutional Codex',
    description: 'CONSTITUTIONAL_CODEX_DESC',
    openStores: ['university'],
  },
  {
    id: 75,
    parent: 'LAW',
    img: 'assets/gpt/law2.svg',
    name: 'CRIMSON_CASEBOOK',
    nameEn: 'Crimson Casebook',
    nameAr: 'Crimson Casebook',
    description: 'CRIMSON_CASEBOOK_DESC',
    openStores: ['university'],
  },
  {
    id: 76,
    parent: 'LAW',
    img: 'assets/gpt/law3.svg',
    name: 'CIVIL_SCRIBE',
    nameEn: 'Civil Scribe',
    nameAr: 'Civil Scribe',
    description: 'CIVIL_SCRIBE_DESC',
    openStores: ['university'],
  },
  {
    id: 77,
    parent: 'LAW',
    img: 'assets/gpt/law4.svg',
    name: 'CONTRACT_CONSIGLIERE',
    nameEn: 'Contract Consigliere',
    nameAr: 'Contract Consigliere',
    description: 'CONTRACT_CONSIGLIERE_DESC',
    openStores: ['university'],
  },
  {
    id: 78,
    parent: 'LAW',
    img: 'assets/gpt/law5.svg',
    name: 'TORT_TRAILBLAZER',
    nameEn: 'Tort Trailblazer',
    nameAr: 'Tort Trailblazer',
    description: 'TORT_TRAILBLAZER_DESC',
    openStores: ['university'],
  },
  {
    id: 79,
    parent: 'LAW',
    img: 'assets/gpt/law6.svg',
    name: 'PROPERTY_PARAGON',
    nameEn: 'Property Paragon',
    nameAr: 'Property Paragon',
    description: 'PROPERTY_PARAGON_DESC',
    openStores: ['university'],
  },
  {
    id: 80,
    parent: 'LAW',
    img: 'assets/gpt/law7.svg',
    name: 'CORPORATE_COUNSELOR',
    nameEn: 'Corporate Counselor',
    nameAr: 'Corporate Counselor',
    description: 'CORPORATE_COUNSELOR_DESC',
    openStores: ['university'],
  },
  {
    id: 81,
    parent: 'LAW',
    img: 'assets/gpt/law8.svg',
    name: 'INTERNATIONAL_INTERPRETER',
    nameEn: 'International Interpreter',
    nameAr: 'International Interpreter',
    description: 'INTERNATIONAL_INTERPRETER_DESC',
    openStores: ['university'],
  },
  {
    id: 82,
    parent: 'LAW',
    img: 'assets/gpt/law9.svg',
    name: 'ECOJUSTICE_ENVOY',
    nameEn: 'EcoJustice Envoy',
    nameAr: 'EcoJustice Envoy',
    description: 'ECOJUSTICE_ENVOY_DESC',
    openStores: ['university'],
  },
  {
    id: 83,
    parent: 'LAW',
    img: 'assets/gpt/law10.svg',
    name: 'IDEA_ADVOCATE',
    nameEn: 'Idea Advocate',
    nameAr: 'Idea Advocate',
    description: 'IDEA_ADVOCATE_DESC',
    openStores: ['university'],
  },
  {
    id: 84,
    parent: 'BUSINESS',
    img: 'assets/gpt/business1.svg',
    name: 'MARKETING_MAVERICK',
    nameEn: 'Marketing Maverick',
    nameAr: 'Marketing Maverick',
    description: 'MARKETING_MAVERICK_DESC',
    openStores: ['university'],
  },
  {
    id: 85,
    parent: 'BUSINESS',
    img: 'assets/gpt/business2.svg',
    name: 'ACCOUNTING_ANALYST',
    nameEn: 'Accounting Analyst',
    nameAr: 'Accounting Analyst',
    description: 'ACCOUNTING_ANALYST_DESC',
    openStores: ['university'],
  },
  {
    id: 86,
    parent: 'BUSINESS',
    img: 'assets/gpt/business3.svg',
    name: 'FISCAL_FUTURIST',
    nameEn: 'Fiscal Futurist',
    nameAr: 'Fiscal Futurist',
    description: 'FISCAL_FUTURIST_DESC',
    openStores: ['university'],
  },
  {
    id: 87,
    parent: 'BUSINESS',
    img: 'assets/gpt/business4.svg',
    name: 'HUMAN_CAPITAL_CURATOR',
    nameEn: 'Human Capital Curator',
    nameAr: 'Human Capital Curator',
    description: 'HUMAN_CAPITAL_CURATOR_DESC',
    openStores: ['university'],
  },
  {
    id: 88,
    parent: 'BUSINESS',
    img: 'assets/gpt/business5.svg',
    name: 'OPERATIONS_ORACLE',
    nameEn: 'Operations Oracle',
    nameAr: 'Operations Oracle',
    description: 'OPERATIONS_ORACLE_DESC',
    openStores: ['university'],
  },
  {
    id: 89,
    parent: 'BUSINESS',
    img: 'assets/gpt/business6.svg',
    name: 'ETHICS_EAGLE',
    nameEn: 'Ethics Eagle',
    nameAr: 'Ethics Eagle',
    description: 'ETHICS_EAGLE_DESC',
    openStores: ['university'],
  },
  {
    id: 90,
    parent: 'BUSINESS',
    img: 'assets/gpt/business7.svg',
    name: 'VENTURE_VIRTUOSO',
    nameEn: 'Venture Virtuoso',
    nameAr: 'Venture Virtuoso',
    description: 'VENTURE_VIRTUOSO_DESC',
    openStores: ['university'],
  },
  {
    id: 91,
    parent: 'BUSINESS',
    img: 'assets/gpt/business8.svg',
    name: 'BORDERLESS_BUSINESS_BARD',
    nameEn: 'Borderless Business Bard',
    nameAr: 'Borderless Business Bard',
    description: 'BORDERLESS_BUSINESS_BARD_DESC',
    openStores: ['university'],
  },
  {
    id: 92,
    parent: 'BUSINESS',
    img: 'assets/gpt/business9.svg',
    name: 'SUPPLY_CHAIN_STRATEGIST',
    nameEn: 'Supply Chain Strategist',
    nameAr: 'Supply Chain Strategist',
    description: 'SUPPLY_CHAIN_STRATEGIST_DESC',
    openStores: ['university'],
  },
  {
    id: 93,
    parent: 'BUSINESS',
    img: 'assets/gpt/business10.svg',
    name: 'ORGANIZATIONAL_ORACLE',
    nameEn: 'Organizational Oracle',
    nameAr: 'Organizational Oracle',
    description: 'ORGANIZATIONAL_ORACLE_DESC',
    openStores: ['university'],
  },
  {
    id: 94,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics1.svg',
    name: 'MICROMASTERMIND',
    nameEn: 'MicroMastermind',
    nameAr: 'MicroMastermind',
    description: 'MICROMASTERMIND_DESC',
    openStores: ['university'],
  },
  {
    id: 95,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics2.svg',
    name: 'MACROMAESTRO',
    nameEn: 'MacroMaestro',
    nameAr: 'MacroMaestro',
    description: 'MACROMAESTRO_DESC',
    openStores: ['university'],
  },
  {
    id: 96,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics3.svg',
    name: 'ECONOMETRIC_EXPLORER',
    nameEn: 'Econometric Explorer',
    nameAr: 'Econometric Explorer',
    description: 'ECONOMETRIC_EXPLORER_DESC',
    openStores: ['university'],
  },
  {
    id: 97,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics4.svg',
    name: 'DEVELOPMENT_DYNAMO',
    nameEn: 'Development Dynamo',
    nameAr: 'Development Dynamo',
    description: 'DEVELOPMENT_DYNAMO_DESC',
    openStores: ['university'],
  },
  {
    id: 98,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics5.svg',
    name: 'CROSSBORDER_ECONOMIST',
    nameEn: 'CrossBorder Economist',
    nameAr: 'CrossBorder Economist',
    description: 'CROSSBORDER_ECONOMIST_DESC',
    openStores: ['university'],
  },
  {
    id: 99,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics6.svg',
    name: 'LABOR_LEDGER',
    nameEn: 'Labor Ledger',
    nameAr: 'Labor Ledger',
    description: 'LABOR_LEDGER_DESC',
    openStores: ['university'],
  },
  {
    id: 100,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics7.svg',
    name: 'PUBLIC_POLICY_PRODIGY',
    nameEn: 'Public Policy Prodigy',
    nameAr: 'Public Policy Prodigy',
    description: 'PUBLIC_POLICY_PRODIGY_DESC',
    openStores: ['university'],
  },
  {
    id: 101,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics8.svg',
    name: 'ECOECONOMIST',
    nameEn: 'EcoEconomist',
    nameAr: 'EcoEconomist',
    description: 'ECOECONOMIST_DESC',
    openStores: ['university'],
  },
  {
    id: 102,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics9.svg',
    name: 'HEALTHCARE_HARBINGER',
    nameEn: 'Healthcare Harbinger',
    nameAr: 'Healthcare Harbinger',
    description: 'HEALTHCARE_HARBINGER_DESC',
    openStores: ['university'],
  },
  {
    id: 103,
    parent: 'ECONOMICS',
    img: 'assets/gpt/economics10.svg',
    name: 'INDUSTRY_ILLUMINATOR',
    nameEn: 'Industry Illuminator',
    nameAr: 'Industry Illuminator',
    description: 'INDUSTRY_ILLUMINATOR_DESC',
    openStores: ['university'],
  },
  {
    id: 104,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer1.svg',
    name: 'CODE_CONDUCTOR',
    nameEn: 'Code Conductor',
    nameAr: 'Code Conductor',
    description: 'CODE_CONDUCTOR_DESC',
    openStores: ['university'],
  },
  {
    id: 105,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer2.svg',
    name: 'DATA_ARCHITECT',
    nameEn: 'Data Architect',
    nameAr: 'Data Architect',
    description: 'DATA_ARCHITECT_DESC',
    openStores: ['university'],
  },
  {
    id: 106,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer3.svg',
    name: 'ALGORITHM_ARTISAN',
    nameEn: 'Algorithm Artisan',
    nameAr: 'Algorithm Artisan',
    description: 'ALGORITHM_ARTISAN_DESC',
    openStores: ['university'],
  },
  {
    id: 107,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer4.svg',
    name: 'SOFTWARE_SAGE',
    nameEn: 'Software Sage',
    nameAr: 'Software Sage',
    description: 'SOFTWARE_SAGE_DESC',
    openStores: ['university'],
  },
  {
    id: 108,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer5.svg',
    name: 'DATABASE_DETECTIVE',
    nameEn: 'Database Detective',
    nameAr: 'Database Detective',
    description: 'DATABASE_DETECTIVE_DESC',
    openStores: ['university'],
  },
  {
    id: 109,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer6.svg',
    name: 'NETWORK_NAVIGATOR',
    nameEn: 'Network Navigator',
    nameAr: 'Network Navigator',
    description: 'NETWORK_NAVIGATOR_DESC',
    openStores: ['university'],
  },
  {
    id: 110,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer7.svg',
    name: 'OS_OVERSEER',
    nameEn: 'OS Overseer',
    nameAr: 'OS Overseer',
    description: 'OS_OVERSEER_DESC',
    openStores: ['university'],
  },
  {
    id: 111,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer8.svg',
    name: 'AI_ARCHITECT',
    nameEn: 'AI Architect',
    nameAr: 'AI Architect',
    description: 'AI_ARCHITECT_DESC',
    openStores: ['university'],
  },
  {
    id: 112,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer9.svg',
    name: 'ML_MAESTRO',
    nameEn: 'ML Maestro',
    nameAr: 'ML Maestro',
    description: 'ML_MAESTRO_DESC',
    openStores: ['university'],
  },
  {
    id: 113,
    parent: 'COMPUTER_SCIENCE',
    img: 'assets/gpt/computer10.svg',
    name: 'HCI_HARMONIZER',
    nameEn: 'HCI Harmonizer',
    nameAr: 'HCI Harmonizer',
    description: 'HCI_HARMONIZER_DESC',
    openStores: ['university'],
  },
];

export const GPTKeyFeatures = [
  {
    id: 'languageAnswers',
    name: 'ENGLISH_LANGUAGE_ANSWERS',
    nameEn: 'English AI',
  },
  {
    id: 'studiesAI',
    name: 'HISTORY_SOCIAL_STUDIES_AI',
    nameEn: 'History / Social Studies AI',
  },
  {
    id: 'scienceAI',
    name: 'SCIENCE_AI',
    nameEn: 'Science AI',
  },
  {
    id: 'mathAI',
    name: 'MATH_AI_BETA',
    nameEn: 'Math AI (beta)',
  },
  {
    id: 'reportAI',
    name: 'ESSAY_BOOK_REPORT_AI',
    nameEn: 'Essay/Book Report AI',
  },
  {
    id: 'friendlyAI',
    name: 'FRIENDLY_AI',
    nameEn: 'Friendly AI',
  },
];

export const GPTAdvancedFeatures = [
  {
    id: 'testPrep',
    name: 'TEST_PREP_SUIT_UNIPREP',
    nameEn: 'Test Prep Suit (SAT, SSAT, ACT, GRE, GMAT, TOFEL)',
  },
  {
    id: 'accessAnytime',
    name: 'UNLIMITED_ACCESS_CANCEL_ANYTIME',
    nameEn: 'Unlimited Access-Cancel anytime',
  },
  {
    id: 'uploadFiles',
    name: 'UPLOAD_ATTACHED_FILES',
    nameEn: 'Upload PDF, PPT, DOC & Image files',
  },
  // {
  //   id: 'voiceText',
  //   name: 'VOICE_TO_TEXT',
  //   nameEn: 'Voice to Text',
  // },
];

export const GPTPackages = [
  {
    id: 1,
    name: 'ESSENTIAL_PACK',
    slugName: 'ESSENTIAL',
    monthlyPrice: 5.99,
    yearlyPrice: 53.91,
    roleId: 4,
    queriesPerMonth: 200,
    [GPTKeyFeatures[0].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[1].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[2].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[3].id]: GPTPackagesIcons.close,
    [GPTKeyFeatures[4].id]: GPTPackagesIcons.close,
    [GPTKeyFeatures[5].id]: GPTPackagesIcons.close,
    [GPTAdvancedFeatures[0].id]: GPTPackagesIcons.close,
    [GPTAdvancedFeatures[1].id]: GPTPackagesIcons.checkbox,
    [GPTAdvancedFeatures[2].id]: GPTPackagesIcons.close,
    // [GPTAdvancedFeatures[3].id]: GPTPackagesIcons.close,
  },
  {
    id: 2,
    name: 'PREMIUM_PACK',
    slugName: 'PREMIUM',
    monthlyPrice: 7.99,
    yearlyPrice: 71.91,
    roleId: 5,
    queriesPerMonth: 300,
    [GPTKeyFeatures[0].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[1].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[2].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[3].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[4].id]: GPTPackagesIcons.checkbox,
    [GPTKeyFeatures[5].id]: GPTPackagesIcons.checkbox,
    [GPTAdvancedFeatures[0].id]: GPTPackagesIcons.checkbox,
    [GPTAdvancedFeatures[1].id]: GPTPackagesIcons.checkbox,
    [GPTAdvancedFeatures[2].id]: GPTPackagesIcons.checkbox,
    // [GPTAdvancedFeatures[3].id]: GPTPackagesIcons.checkbox,
  },
  {
    id: 3,
    name: 'BASIC_PACK',
    slugName: 'BASIC',
    monthlyPrice: 3.99,
    yearlyPrice: 35.91,
    roleId: 6,
    queriesPerMonth: 150,
    [GPTKeyFeatures[0].id]: GPTPackagesIcons.radio,
    [GPTKeyFeatures[1].id]: GPTPackagesIcons.radio,
    [GPTKeyFeatures[2].id]: GPTPackagesIcons.radio,
    [GPTKeyFeatures[3].id]: GPTPackagesIcons.radio,
    [GPTKeyFeatures[4].id]: GPTPackagesIcons.close,
    [GPTKeyFeatures[5].id]: GPTPackagesIcons.close,
    [GPTAdvancedFeatures[0].id]: GPTPackagesIcons.close,
    [GPTAdvancedFeatures[1].id]: GPTPackagesIcons.checkbox,
    [GPTAdvancedFeatures[2].id]: GPTPackagesIcons.close,
    // [GPTAdvancedFeatures[3].id]: GPTPackagesIcons.close,
  },
];

export const GPTLearningPackage = [
  {
    id: 4,
    name: 'LEARNING_PACK',
    roleId: 7,
  },
];
