export const generalConstants = {
  nationalId: 3,
  trialSubscriptionDays: 30,
  planIds: {
    trial: 5,
    basic: 3,
    refill: 4,
    premium: 2,
    learning: 6,
  },
  defaultAvatarPath: 'assets/defaults/avatar.svg',
  defaultFemalePath: 'assets/defaults/female.svg',
  regex: {
    password: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$',
    url:
      '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$',
  },
};
