import { createAction, props } from '@ngrx/store';

import { ICountry, IProgram, IRole } from '@models';

export const loadUserTypes = createAction('[Lookups] Load User Types');

export const loadUserTypesSuccess = createAction(
  '[Lookups] Load User Types Success',
  props<{ userTypes: IRole[] }>()
);

export const loadUserTypesFailure = createAction(
  '[Lookups] Load User Types Failure',
  props<{ error: any }>()
);

export const loadUserTypesEnded = createAction(
  '[Lookups] Load User Types Ended'
);

export const loadProgramCountries = createAction(
  '[Lookups] Load Program Countries'
);

export const loadProgramCountriesSuccess = createAction(
  '[Lookups] Load Program Countries Success',
  props<{ countries: ICountry[] }>()
);

export const loadProgramCountriesFailure = createAction(
  '[Lookups] Load Program Countries Failure',
  props<{ error: any }>()
);

export const loadProgramCountriesEnded = createAction(
  '[Lookups] Load Program Countries Ended'
);

export const loadPrograms = createAction('[Lookups] Load Programs');

export const loadProgramsSuccess = createAction(
  '[Lookups] Load Programs Success',
  props<{ programs: IProgram[] }>()
);

export const loadProgramsFailure = createAction(
  '[Lookups] Load Programs Failure',
  props<{ error: any }>()
);

export const loadProgramsEnded = createAction('[Lookups] Load Programs Ended');
