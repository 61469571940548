import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  Component,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'metutors-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
  @Input() paymentInfo: any;
  @Input() additionalFields = false;
  @Input() showModal: boolean = false;

  @Output() closeModal = new EventEmitter();

  loading = true;

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    // todo: hacky not recommended find a better solution read docs in detail
    if (this.paymentInfo?.shopperResultUrl) {
      let node = document.createElement('script');
      node.src = this.paymentInfo?.script_url;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);

      if (this.additionalFields) {
        let script = this._renderer2.createElement('script');
        script.text = `
        var wpwlOptions = {
              billingAddress: {},
              mandatoryBillingFields: {
                  country: true,
                  state: true,
                  city: true,
                  postcode: true,
                  street1: true,
                  street2: false
              }
            }
        `;

        this._renderer2.appendChild(this._document.head, script);
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    } else {
      this.loading = false;
      document.location.href = this.paymentInfo?.redirect_url;
      window.location = this.paymentInfo?.redirect_url;
    }
  }
}
