import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  uploadedFiles: any[] = [];
  fileUploadProgress: any[] = [];
  fileUploadStream$: Subscription;

  baseUrl = environment.API_URL;
  private _httpClient: HttpClient;

  constructor(public handler: HttpBackend) {
    this._httpClient = new HttpClient(handler);
  }

  UploadOpenAIFile(file: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${environment.openAIKey}`,
    });
    const formdata = new FormData();
    formdata.append('purpose', 'assistants');
    formdata.append('file', file);

    return this._httpClient.post('https://api.openai.com/v1/files', formdata, {
      headers,
    });
  }

  cancelFileUploadStream(): Observable<any> {
    this.fileUploadStream$.unsubscribe();

    return of({});
  }
}
