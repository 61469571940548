import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'metutors-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
  @Input() hint: string;
  @Input() size = 'medium';
  @Input() heading: string;
  @Input() routerLink: any;
  @Input() panelClass: string;
  @Input() showModal: boolean;
  @Input() subHeading: string;
  @Input() buttonLabel: string;
  @Input() extraClasses: string;
  @Input() icon = 'green-tick.svg';

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
